import { useState } from 'react';
import { FaChevronDown } from 'react-icons/fa6';
import './PrivacyPolicy.module.scss';

const accordianData = [
  {
    title: 'What personal information do we process?',
    content:
      'When you visit, use, or navigate our Services, we may process personal information depending on how you interact with us and the Services, the choices you make and the products and features you use. Learn more about personal information you disclose to us.',
  },

  {
    title: 'Do we process any sensitive personal information?',
    content:
      'We may process sensitive personal information, when necessary, with your consent or as otherwise permitted by applicable law. Learn more about sensitive information we process',
  },
  {
    title: 'Do we receive any information from third parties?',
    content: 'We do not receive any information from third parties.',
  },
  {
    title: 'How do we process your information?',
    content:
      'We process your information to provide, improve, and administer our Services, communicate with you, for security and fraud prevention, and to comply with law. We may also process your information for other purposes with your consent. We process your information only when we have a valid legal reason to do so. Learn more about how we process your information.',
  },
  {
    title:
      'In what situations and with which parties do we share personal information?',
    content:
      'We may share information in specific situations and with specific third parties. Learn more about when and with whom we share your personal information',
  },
  {
    title: 'How do we keep your information safe?',
    content:
      'We have organisational and technical processes and procedures in place to protect your personal information. However, no electronic transmission over the internet or information storage technology can be guaranteed to be 100% secure, so we cannot promise or guarantee that hackers, cybercriminals, or other unauthorised third parties will not be able to defeat our security and improperly collect, access, steal, or modify your information. Learn more about how we keep your information safe.',
  },
  {
    title: 'What are your rights? ',
    content:
      'Depending on where you are located geographically, the applicable privacy law may mean you have certain rights regarding your personal information. Learn more about your privacy rights.',
  },
  {
    title: 'How do you exercise your rights?',
    content:
      'The easiest way to exercise your rights is by visiting support.kraverich.com, or by contacting us. We will consider and act upon any request in accordance with applicable data protection laws.',
  },
];

const tableOfContents = [
  { heading: 'What information do we collect?', id: 'point1' },
  {
    heading: 'How do we process your infomation?',
    id: 'point2',
  },
  {
    heading: 'When and with whom do we share your personal information?',
    id: 'point3',
  },
  {
    heading: 'Do we use cookies and other tracking technologies',
    id: 'point4',
  },
  {
    heading: 'How long do we keep your information?',
    id: 'point5',
  },
  { heading: 'How do we keep your information safe?', id: 'point6' },
  { heading: 'What are your rights?', id: 'point7' },
  { heading: 'How do you exercise your rights?', id: 'point8' },
  {
    heading: 'Do we make updates to this notice?',
    id: 'point9',
  },
  {
    heading: 'How can you contact us about this notice?',
    id: 'point10',
  },
  {
    heading:
      'How can you review, update or delete the dta we collect from you?',
    id: 'point11',
  },
];

const Accordian = ({ title, content }) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <div
      className='p-4 border-2 rounded-lg w-full'
      onClick={e => setIsOpen(!isOpen)}
    >
      <div className='flex items-center justify-between duration-300'>
        <h1 className='text-xl font-bold cursor-pointer'>{title}</h1>
        <FaChevronDown
          className={`${isOpen ? 'rotate-[-180deg]' : ''} cursor-pointer`}
        />
      </div>
      {isOpen && <p className='mt-4'>{content}</p>}
    </div>
  );
};

const PrivacyPolicy = () => {
  return (
    <div className='bg-[#F9F9F9]'>
      <div className='md:pt-2 md:mx-48 mx-10 pt-1 text-[#475467] '>
        <div className='text-center mt-10'>
          <h1 className='title md:text-5xl text-[40px] text-purple'>
            Privacy Policy
          </h1>
        </div>

        <section className='my-16'>
          <h1 className='text-2xl'>TABLE OF CONTENTS</h1>
          <ol className='list-decimal ml-10 my-10'>
            {tableOfContents.map((item, index) => {
              return (
                <li
                  key={index}
                  className='cursor-pointer my-2 text-lg underline'
                >
                  <a href={`#${item.id}`}>{item.heading}</a>
                </li>
              );
            })}
          </ol>
        </section>

        <section id='point1' className='my-10 flex flex-col gap-5'>
          <h1 className='uppercase text-2xl font-bold'>
            1. WHAT INFORMATION DO WE COLLECT?
          </h1>
          <p>
            We collect personal information that you voluntarily provide to us
            when you register on the Services, express an interest in obtaining
            information about us or our products and Services, when you
            participate in activities on the Services, or otherwise when you
            contact us. This platform is intended for users above the age of 18.
            We neither collect personal information from individuals below this
            age nor target them with marketing or information requests. If we
            discover that a user under 18 has provided personal information, we
            will immediately remove their account and any related data.
          </p>
          <p>
            <strong>Information Provided by You</strong> The personal
            information that we collect depends on the context of your
            interactions with us and the Services, the choices you make, and the
            products and features you use. The personal information we collect
            may include the following:
          </p>
          <ul className='list-disc ml-10'>
            <li>
              Account information such as names, phone numbers, age, gender, and
              email address.
            </li>
            <li>
              Information generated when you use our services such as search
              history and food ordering history.
            </li>
            <li>height</li>
            <li>weight</li>
            <li>itness lifestyle</li>
            <li>mailing addresses</li>
            <li>debit/credit card numbers</li>
            <li>Your preferences on the app and settings</li>
            <li>cuisine preferences</li>
            <li>ratings</li>
            <li>messages</li>
            <li>fitness goals</li>
            <li>dietary preferences</li>
          </ul>
          <p>
            All personal information that you provide to us must be true,
            complete, and accurate, and you must notify us of any changes to
            such personal information.
          </p>
          <p>
            <strong>Sensitive Information:</strong> When necessary, with your
            consent or as otherwise permitted by applicable law, we process your
            health data.
          </p>
          <p>
            <strong> Payment Data:</strong> We may collect data necessary to
            process your payment if you make purchases, such as your payment
            instrument number, and the security code associated with your
            payment instrument. All payment data is stored by Razor Pay. You may
            find their privacy notice link(s){' '}
            <strong>here: https://razorpay.com/privacy/.</strong>
          </p>
          <p>
            <strong>Call recordings</strong> We record any calls you make to
            access our services, including those made to our restaurant partners
            and delivery partners. This data helps us improve our services and
            to solve any disputes that may arise.
          </p>
          <p>
            <strong> Job Applications</strong> When you submit your application,
            we use it to evaluate your fit for the position. We may keep your
            information on file for future opportunities. To help us find the
            best candidates, we might share your info with other affiliated
            companies, partners or use trusted third-party services.
          </p>

          <h2 className='capitalize text-xl font-medium mt-10'>
            Information automatically collected
          </h2>
          <p>
            <i>
              In Short: Some information — such as your Internet Protocol (IP)
              address and/or browser and device characteristics — is collected
              automatically when you visit our Services.
            </i>
          </p>
          <p>
            We automatically collect certain information when you visit, use, or
            navigate the Services. This information may include device and usage
            information, such as your IP address, browser and device
            characteristics, operating system, language preferences, referring
            URLs, device name, country, location, information about how and when
            you use our Services, and other technical information. This
            information is primarily needed to maintain the security and
            operation of our Services, and for our internal analytics and
            reporting purposes. Like many businesses, we also collect
            information through cookies and similar technologies.
          </p>
          <p>The information we collect includes:</p>
          <ul className='list-disc ml-10'>
            <li className='my-3'>
              Log and Usage Data. Log and usage data is service-related,
              diagnostic, usage, and performance information our servers
              automatically collect when you access or use our Services and
              which we record in log files. Depending on how you interact with
              us, this log data may include your IP address, device information,
              browser type, and settings and information about your activity in
              the Services (such as the date/time stamps associated with your
              usage, pages and files viewed, searches, and other actions you
              take such as which features you use), device event information
              (such as system activity, error reports (sometimes called 'crash
              dumps'), and hardware settings).
            </li>
            <li className='my-3'>
              Device Data. We collect device data such as information about your
              computer, phone, tablet, or other device you use to access the
              Services. Depending on the device used, this device data may
              include information such as your IP address (or proxy server),
              device and application identification numbers, location, browser
              type, hardware model, Internet service provider and/or mobile
              carrier, operating system, and system configuration information.
              If you are using our application(s), we may also collect
              information about the phone network associated with your mobile
              device, your mobile device’s operating system or platform, the
              type of mobile device you use, your mobile device’s unique device
              ID, and information about the features of our application(s) you
              accessed.
            </li>
            <li className='my-3'>
              Mobile Device Access. We may request access or permission to
              certain features from your mobile device, including your mobile
              device's SMS messages, reminders, and other features. If you wish
              to change our access or permissions, you may do so in your
              device's settings.
            </li>
            <li className='my-3'>
              Location Data. We collect location data such as information about
              your device's location, which can be either precise or imprecise.
              How much information we collect depends on the type and settings
              of the device you use to access the Services. For example, we may
              use GPS and other technologies to collect geolocation data that
              tells us your current location (based on your IP address). You can
              opt out of allowing us to collect this information either by
              refusing access to the information or by disabling your Location
              setting on your device. However, if you choose to opt out, you may
              not be able to use certain aspects of the Services.
            </li>
            <li className='my-3'>
              Push Notifications. We may request to send you push notifications
              regarding your account or certain features of the application(s)
              such as order updates and . If you wish to opt out from receiving
              these types of communications, you may turn them off in your
              device's settings.
            </li>
          </ul>
        </section>

        <section id='point2' className='flex flex-col gap-5 my-10'>
          <h1 className='uppercase font-bold text-2xl'>
            2. HOW DO WE PROCESS YOUR INFORMATION?
          </h1>
          <p>
            <strong>In Short:</strong> We process your information to provide,
            improve, and administer our Services, communicate with you, for
            security and fraud prevention, and to comply with law. We may also
            process your information for other purposes with your consent.
          </p>
          <strong>
            We process your personal information for a variety of reasons,
            depending on how you interact with our Services, including:
          </strong>
          <ul className='list-disc ml-5'>
            <li className='my-5'>
              <strong>
                {' '}
                To facilitate account creation and authentication and otherwise
                manage user accounts.
              </strong>{' '}
              We may process your information so you can create and log in to
              your account, as well as keep your account in working order.
            </li>
            <li className='my-5'>
              <strong>
                {' '}
                To deliver and facilitate delivery of services to the user.
              </strong>{' '}
              We may process your information to provide you with the requested
              service.
            </li>
            <li className='my-5'>
              <strong>
                {' '}
                To respond to user inquiries/offer support to users.{' '}
              </strong>
              We may process your information to respond to your inquiries and
              solve any potential issues you might have with the requested
              service.
            </li>
            <li className='my-5'>
              <strong>To fulfil and manage your orders.</strong> We may process
              your information to fulfil and manage your orders, payments,
              returns, and exchanges made through the Services.
            </li>
            <li className='my-5'>
              <strong>To request feedback.</strong> We may process your
              information when necessary to request feedback and to contact you
              about your use of our Services.
            </li>
            <li className='my-5'>
              <strong>To post testimonials. </strong>We post testimonials on our
              Services that may contain personal information.
            </li>
            <li className='my-5'>
              <strong>
                To evaluate and improve our Services, products, marketing, and
                your experience.
              </strong>{' '}
              We may process your information when we believe it is necessary to
              identify usage trends, determine the effectiveness of our
              promotional campaigns, and to evaluate and improve our Services,
              products, marketing, and your experience.
            </li>
            <li className='my-5'>
              <strong>To identify usage trends.</strong> We may process
              information about how you use our Services to better understand
              how they are being used so we can improve them.
            </li>
            <li className='my-5'>
              <strong> To comply with our legal obligations.</strong> We may
              process your information to comply with our legal obligations,
              respond to legal requests, and exercise, establish, or defend our
              legal rights.
            </li>
          </ul>
        </section>

        <section id='point3' className='flex flex-col gap-5 my-10'>
          <h1 className='text-2xl font-bold uppercase'>
            3. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?
          </h1>
          <p>
            <strong>In Short:</strong> We may share information in specific
            situations described in this section and/or with the following third
            parties, or for any other purpose with your consent.
          </p>
          <p>
            Other than to fulfil the purpose for which you provide it, we may
            need to share your information in the following situations:
          </p>
          <ul className='ml-10 list-disc'>
            <li className='my-5'>
              <strong> Business Transfers:</strong> We may share or transfer
              your information in connection with, or during negotiations of,
              any merger, sale of company assets, financing, or acquisition of
              all or a portion of our business to another company.
            </li>
            <li className='my-5'>
              <strong> When we use Google Maps Platform APIs: </strong>We may
              share your information with certain Google Maps Platform APIs
              (e.g. Google Maps API, Places API). We obtain and store on your
              device ('cache') your location. You may revoke your consent
              anytime by contacting us at the contact details provided at the
              end of this document.
            </li>
            <li className='my-5'>
              <strong> Services:</strong> We collaborate with external partners
              for various tasks, including marketing via email, mobile
              notifications, and voice assistance. They also help us analyse
              service usage and manage payments. Some of our data resides with
              third-party hosting providers.
            </li>
            <li className='my-5'>
              <strong> Affiliates and Partners:</strong> We may share your
              information with our affiliates and partners, in which case we
              will require those affiliates and partners to honour this privacy
              notice. These include but are not limited to, our parent company
              and any subsidiaries, nutritionists, restaurant partners and
              delivery partners, other companies that we control, work with to
              improve the services or that are under common control with us.
            </li>
            <li className='my-5'>
              <strong> Anonymous data:</strong> The information we collect about
              you might be made anonymous or disguised (without any details that
              could reveal who you are). This processed information, which
              doesn't identify you personally, isn't covered by this privacy
              policy. We can use and share it freely with others without any
              restrictions.
            </li>
            <li className='my-5'>
              <strong>Legal Reasons:</strong> We might disclose your information
              if we genuinely believe it's reasonably necessary to investigate,
              prevent, or act against potential illegal activities, or to comply
              with legal procedures. Additionally, we may share your information
              to investigate and address threats to the physical safety of
              individuals, violations of our Privacy Policy or Terms of Service,
              or infringements on the rights of third parties. This could entail
              sharing your information with law enforcement, government
              agencies, courts, or other organizations in response to legal
              requests, such as subpoenas, court orders, or government demands
              to comply with the law.
            </li>
          </ul>
        </section>

        <section id='point4' className='flex flex-col gap-5 my-10'>
          <h1 className='text-2xl font-bold'>
            4. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?
          </h1>
          <p>
            <strong>In Short:</strong> We may use cookies and other tracking
            technologies to collect and store your information. We may use
            cookies and similar tracking technologies (like web beacons and
            pixels) to access or store information. We may provide this
            information to our third-party partners who we trust. You may learn
            more about cookies from https://www.aboutcookies.org.
          </p>
        </section>

        <section id='point5' className='flex flex-col gap-5 my-10'>
          <h1 className='text-2xl font-bold'>
            5. HOW LONG DO WE KEEP YOUR INFORMATION?
          </h1>
          <p>
            In Short: We keep your information for as long as necessary to
            fulfil the purposes outlined in this privacy notice unless otherwise
            required by law.
          </p>
          <p>
            We will only keep your personal information for as long as it is
            necessary for the purposes set out in this privacy notice, unless a
            longer retention period is required or permitted by law (such as
            tax, accounting, or other legal requirements). No purpose in this
            notice will require us keeping your personal information for longer
            than the period of time in which users have an account and 3 months
            after account deletion with us.
          </p>
          <p>
            When we have no ongoing legitimate business need to process your
            personal information, we will either delete or anonymise such
            information, or, if this is not possible (for example, because your
            personal information has been stored in backup archives), then we
            will securely store your personal information and isolate it from
            any further processing until deletion is possible.
          </p>
        </section>

        <section id='point6' className='flex flex-col gap-5 my-10'>
          <h1 className='text-2xl font-bold'>
            6. HOW DO WE KEEP YOUR INFORMATION SAFE?
          </h1>
          <p>
            <strong>In Short:</strong> We aim to protect your personal
            information through a system of organisational and technical
            security measures.
          </p>
          <p>
            We have implemented appropriate and reasonable technical and
            organisational security measures designed to protect the security of
            any personal information we process. However, despite our safeguards
            and efforts to secure your information, no electronic transmission
            over the Internet or information storage technology can be
            guaranteed to be 100% secure, so we cannot promise or guarantee that
            hackers, cybercriminals, or other unauthorised third parties will
            not be able to defeat our security and improperly collect, access,
            steal, or modify your information. Although we will do our best to
            protect your personal information, transmission of personal
            information to and from our Services is at your own risk. You should
            only access the Services within a secure environment.
          </p>
          <p>
            In the event we become aware of a data breach involving the
            unauthorized access, disclosure, or acquisition of personal
            information, we are committed to promptly notifying affected
            individuals in accordance with applicable data protection laws and
            regulations. Notification will be provided via email or other means
            deemed appropriate by us and will include information about the
            nature of the breach, the types of personal information affected,
            and the steps individuals can take to mitigate potential harm.
            Additionally, we will notify relevant authorities and regulatory
            bodies as required by law. We take the security of your personal
            information seriously and will take all necessary measures to
            address and mitigate the impact of any data breach.
          </p>
        </section>

        <section id='point7' className='flex flex-col gap-5 my-10'>
          <h1 className='text-2xl font-bold'>
            7. WHAT ARE YOUR PRIVACY RIGHTS?
          </h1>
          <p>
            <strong>In Short:</strong> You may review, change, or terminate your
            account at any time.
          </p>
          <p>
            Withdrawing your consent: If we are relying on your consent to
            process your personal information, which may be express and/or
            implied consent depending on the applicable law, you have the right
            to withdraw your consent at any time. You can withdraw your consent
            at any time by contacting us by using the contact details provided
            in the section 'HOW CAN YOU CONTACT US ABOUT THIS NOTICE?' below.
            However, please note that this will not affect the lawfulness of the
            processing before its withdrawal nor, when applicable law allows,
            will it affect the processing of your personal information conducted
            in reliance on lawful processing grounds other than consent.
          </p>

          <h3 className='text-xl'>Account Information</h3>
          <p>
            If you would at any time like to review or change the information in
            your account or terminate your account, you can:
          </p>
          <ul className='ml-10 list-disc'>
            <li className='my-5'>
              Log in to your account settings and update your user account.
            </li>
            <li className='my-5'>
              Contact us using the contact information provided.
            </li>
          </ul>
          <p>
            Upon your request to terminate your account, we will deactivate or
            delete your account and information from our active databases.
            However, we may retain some information in our files to prevent
            fraud, troubleshoot problems, assist with any investigations,
            enforce our legal terms and/or comply with applicable legal
            requirements.
          </p>
          <p>
            <strong>Cookies and similar technologies: </strong>
            Most Web browsers are set to accept cookies by default. If you
            prefer, you can usually choose to set your browser to remove cookies
            and to reject cookies. If you choose to remove cookies or reject
            cookies, this could affect certain features or services of our
            Services.
          </p>
          <p>
            If you have questions or comments about your privacy rights, you may
            email us at <u>support@kraverich.com.</u>
          </p>
        </section>

        <section id='point8' className='flex flex-col gap-5 my-10'>
          <h1 className='font-bold text-2xl '>
            {' '}
            8. CONTROLS FOR DO-NOT-TRACK FEATURES
          </h1>
          <p>
            Most web browsers and some mobile operating systems and mobile
            applications include a Do-Not-Track ('DNT') feature or setting you
            can activate to signal your privacy preference not to have data
            about your online browsing activities monitored and collected. At
            this stage no uniform technology standard for recognising and
            implementing DNT signals has been finalised. As such, we do not
            currently respond to DNT browser signals or any other mechanism that
            automatically communicates your choice not to be tracked online. If
            a standard for online tracking is adopted that we must follow in the
            future, we will inform you about that practice in a revised version
            of this privacy notice.
          </p>
        </section>
        <section id='point9' className='flex flex-col gap-5 my-10'>
          <h1 className='font-bold text-2xl '>
            {' '}
            9. DO WE MAKE UPDATES TO THIS NOTICE?
          </h1>
          <p>
            <strong>In Short:</strong> Yes, we will update this notice as
            necessary to stay compliant with relevant laws. We may update this
            privacy notice from time to time. The updated version will be
            indicated by an updated 'Revised' date and the updated version will
            be effective as soon as it is accessible. If we make material
            changes to this privacy notice, we will change the 'Last updated'
            date of this Privacy Policy, and you waive any right to receive
            specific notice of each such change, even though we may provide such
            notice. It is your responsibility to periodically review this
            Privacy Policy to stay informed of updates.
          </p>
        </section>

        <section id='point10' className='flex flex-col gap-5 my-10'>
          <h1 className='font-bold text-2xl '>
            10. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?
          </h1>
          <p>
            If you have questions or comments about this notice, you may email
            us at support@kraverich.com or contact us by post at:
          </p>
          <address>
            <br />
            KraveRich Retail Private Limited
            <br />
            Lift Off, CIIE-IIMA
            <br />
            IIM Ahmedabad, Vastrapur, Ahmedabad Ahmedabad, Gujarat 380015
            <br />
            India
            <br />
          </address>
        </section>

        <section id='point11' className='flex flex-col gap-5 my-10'>
          <h1 className='font-bold text-2xl '>
            11. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM
            YOU?
          </h1>
          <p>
            Based on the applicable laws of India, you may have the right to
            request access to the personal information we collect from you,
            change that information, or delete it. To request to review, update,
            or delete your personal information, please visit
            https://www.support.kraverich.com or mail us at{' '}
            <u> support@kraverich.com.</u>
          </p>
        </section>

        <section className='flex flex-col gap-5 my-10'>
          <p className='my-5'>
            This privacy notice for KraveRich Retail Private Limited (doing
            business as KraveRich) ('we', 'us', or 'our'), describes how and why
            we might collect, store, use, and/or share ('process') your
            information when you use our services ('Services'), such as when
            you: • Download and use our mobile application (KraveRich), or any
            other application of ours that links to this privacy notice. •
            Engage with us in other related ways, including any sales,
            marketing, or events. Questions or concerns? Reading this privacy
            notice will help you understand your privacy rights and choices. If
            you do not agree with our policies and practices, please do not use
            our Services. If you still have any questions or concerns, please
            contact us at support@kraverich.com.
          </p>

          {accordianData.map((data, index) => {
            return (
              <Accordian
                key={index}
                title={data.title}
                content={data.content}
              />
            );
          })}

          <p>
            Want to learn more about what we do with any information we collect?
            Review the privacy notice in full.
          </p>
        </section>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
