import './Guidelines.module.scss';

const Guidelines = () => {
  return (
    <div className='bg-[#F9F9F9]'>
      <div className='md:pt-1 md:mx-48 mx-10 pt-1 text-[#475467]'>
        <div className='text-center my-10'>
          <h1 className='title md:text-5xl text-[40px] text-purple'>
            KraveRich's Policies and Guidelines
          </h1>
        </div>
        <p className='my-5'>
          At KraveRich, we're dedicated to fostering a community where sharing
          culinary experiences is both rewarding and constructive. Your input is
          pivotal in shaping the tailored food suggestions provided by our
          partner restaurants. To uphold a vibrant and valuable community, we've
          established guidelines and a Code of Conduct that all KraveRich users
          are expected to abide by.
          <br />
          <br />
          Should your actions on KraveRich veer from these guidelines, we
          reserve the right to take necessary steps, such as adjusting or
          removing reviews, limiting your activity, or terminating your
          KraveRich account, with or without prior notification. Our platform
          utilizes algorithms to detect and address any suspicious activities.
          <br />
          <br />
          If you encounter content that breaches these guidelines or our Terms
          of Service, please report it. However, given the diversity of our
          community, content that may be disagreeable to you may not necessarily
          meet the removal criteria.
        </p>
        <section id='point144' className='my-10 flex flex-col gap-5'>
          <h1 className='uppercase text-2xl font-bold'>
            KraveRich Code of Conduct
          </h1>
          <ol className='ml-10 flex flex-col gap-5 list-decimal'>
            <li>
              Be concise: Ensure your reviews are informative yet concise, with
              at least 140 characters for dine-in experiences. For online
              orders, while there's no character limit, we encourage clarity and
              relevance.
            </li>
            <li>
              Stay relevant: Share content pertinent to KraveRich and refrain
              from posting irrelevant, inappropriate, or promotional material.
              Reviews containing spam, server names, or digital signatures
              violating restaurant policies may be subject to removal.
            </li>
            <li>
              {' '}
              Use respectful language: Avoid using offensive language in your
              reviews and refrain from making derogatory comments based on
              protected characteristics or engaging in personal vendettas
              against businesses.
            </li>
            <li>
              Keep it current: Submit only one review per restaurant for each
              dining experience or online order. Reviews older than six months
              may be deleted.
            </li>
            <li>
              {' '}
              Be truthful: Base your reviews on personal experiences and facts,
              avoiding hearsay or media reports. Reviews from individuals who
              haven't used the restaurant's services may be removed.
            </li>
            <li>
              Avoid solicitation: Do not engage in explicit or implied
              agreements for reviews in exchange for benefits and avoid
              participating in social media groups offering incentives for
              reviews.
            </li>
            <li>
              Create original content: Plagiarism is strictly prohibited; ensure
              your reviews and photos are original. Do not copy content from
              KraveRich or other platforms.
            </li>
            <li>
              Disclose affiliations: If you receive complimentary meals or have
              relevant associations with a restaurant, disclose it in your
              review.
            </li>
            <li>
              Maintain authenticity: While using a screen name is acceptable,
              avoid using inappropriate names, bios, or profile images.
            </li>
            <li>
              Prevent misrepresentation: Avoid identifying or presenting
              yourself as an official KraveRich blogger.
            </li>
            <li>
              No bullying: Blackmail or threatening behavior against restaurants
              and associates will not be tolerated.
            </li>
            <li>
              Adhere to the rules: Follow KraveRich's Terms and Conditions and
              adhere to local laws and regulations. Content may be moderated or
              removed to ensure compliance.
            </li>
          </ol>
        </section>
        <section id='point21' className='my-10 flex flex-col gap-5'>
          <h1 className='uppercase text-2xl font-bold'>Photo Guidelines</h1>
          <p>
            Capture the essence of your culinary experiences with photos that
            adhere to our guidelines:
          </p>

          <ul className='list-disc ml-5'>
            <li>
              Clear, focused, and appetizing food shots are preferred for
              restaurant photo galleries.
            </li>
            <li>
              Ambiance shots that showcase the overall atmosphere of a place are
              encouraged.
            </li>
            <li>
              Photos not featured on a restaurant's page may appear with your
              review or profile based on their relevance.
            </li>
            <li>
              Violations of these guidelines may result in deductions of foodie
              points or deletion of photos.
            </li>
          </ul>
        </section>
        <section id='point2' className='my-10 flex flex-col gap-5'>
          <h1 className='uppercase text-2xl font-bold'>
            KraveRich Employee Code of Ethics
          </h1>
          <p>
            KraveRich employees are expected to maintain the highest standards
            of ethics and integrity:
          </p>
          <ul className='list-disc ml-5'>
            <li>
              Employees must refrain from using their status to solicit
              discounts or special treatment unless on official business.
            </li>
            <li>
              Client-facing employees are prohibited from writing reviews or
              giving ratings on KraveRich.
            </li>
            <li>
              Review and photo moderators must act impartially to maintain a
              neutral platform.
            </li>
            <li>
              Any behavior not in compliance with this code should be reported
              to legal@KraveRich.com for investigation and appropriate action.
            </li>
          </ul>
        </section>
        <section id='point3' className='my-10 flex flex-col gap-5'>
          <h1 className='uppercase text-2xl font-bold'>4. USER REGISTRATION</h1>
          <p>
            You may be required to register to use the Services. You agree to
            keep your password confidential and will be responsible for all use
            of your account and password. We reserve the right to remove,
            reclaim, or change a username you select if we determine, in our
            sole discretion, that such username is inappropriate, obscene, or
            otherwise objectionable.
          </p>
        </section>
      </div>
    </div>
  );
};

export default Guidelines;
