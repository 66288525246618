import './TermsAndConditions.module.scss';

const tableOfContents = [
  { heading: 'OUR SERVICES', id: 'point144' },
  {
    heading: 'INTELLECTUAL PROPERTY RIGHTS',
    id: 'point21',
  },
  {
    heading: 'USER REPRESENTATIONS',
    id: 'point2',
  },
  {
    heading: 'USER REGISTRATION',
    id: 'point3',
  },
  {
    heading: 'PRODUCTS',
    id: 'point4',
  },
  {
    heading: 'PURCHASES AND PAYMENT',
    id: 'point5',
  },
  {
    heading: 'RETURN POLICY',
    id: 'point6',
  },
  {
    heading: 'PROHIBITED ACTIVITIES',
    id: 'point7',
  },
  {
    heading: 'USER GENERATED CONTRIBUTIONS',
    id: 'point8',
  },
  {
    heading: 'CONTRIBUTION LICENCE',
    id: 'point9',
  },
  {
    heading: 'MOBILE APPLICATION LICENCE',
    id: 'point10',
  },
  {
    heading: 'SERVICES MANAGEMENT',
    id: 'point11',
  },
  {
    heading: 'PRIVACY POLICY AND CONTENT GUIDELINES',
    id: 'point12',
  },
  {
    heading: 'TERM AND TERMINATION',
    id: 'point13',
  },
  {
    heading: 'MODIFICATIONS AND INTERRUPTIONS',
    id: 'point14',
  },
  {
    heading: 'GOVERNING LAW',
    id: 'point15',
  },
  {
    heading: 'DISPUTE RESOLUTION',
    id: 'point16',
  },
  {
    heading: 'CORRECTIONS',
    id: 'point17',
  },
  {
    heading: 'DISCLAIMER',
    id: 'point18',
  },
  {
    heading: 'LIMITATIONS OF LIABILITY',
    id: 'point19',
  },
  {
    heading: 'INDEMNIFICATION',
    id: 'point20',
  },
  {
    heading: 'USER DATA',
    id: 'point21',
  },
  {
    heading: 'ELECTRONIC COMMUNICATIONS, TRANSACTIONS, AND SIGNATURES',
    id: 'point22',
  },
  {
    heading: 'MISCELLANEOUS',
    id: 'point23',
  },
  {
    heading: 'CONTACT US',
    id: 'point24',
  },
];

const TermsAndConditions = () => {
  return (
    <div className='bg-[#F9F9F9]'>
      <div className='md:pt-1 pt-1 md:mx-48 mx-10 text-[#475467]'>
        <div className='text-center my-10'>
          <h1 className='title md:text-5xl text-[40px] text-purple'>
            Terms of Service
          </h1>
        </div>
        <p className='my-5'>
          We are KraveRich Retail Private Limited, doing business as KraveRich{' '}
          <strong>('Company', 'we', 'us', or 'our'),</strong> a company
          registered in India.
        </p>
        <p className='my-5'>
          We operate the website https://www.kraverich.com (the 'Site',
          ‘Website’), the mobile application KraveRich (the{' '}
          <strong>'App'</strong>
          ), as well as any other related products and services that refer or
          link to these legal terms{' '}
          <strong>(the 'Legal Terms', ‘terms’)</strong> (collectively, the
          'Services'). The Site and the App have been jointly referred to as
          ‘platform’.
        </p>
        <p className='my-5'>
          Our platform monitors and tracks food intake, helping users make
          informed dietary choices by providing diet recommendations from expert
          ‘Nutritionists’ and delivering customized meals from our restaurant
          partners(‘Restaurant(s)’). We employ sophisticated nutritional
          algorithms to assess users' dietary preferences and goals. Meal
          recommendations are made by expert nutritionists, based on consumer
          profiles.
        </p>
        <p className='my-5'>
          <strong>
            {' '}
            These Terms apply to everyone who uses KraveRich, including those
            who order food and our restaurant partners.{' '}
          </strong>{' '}
          These Legal Terms constitute a legally binding agreement made between
          you, whether personally or on behalf of an entity ('you', ‘your’,
          ‘customer’, ‘consumer’), and KraveRich Retail Private Limited,
          concerning your access to and use of the Services. You agree that by
          accessing the Services, you have read, understood, and agreed to be
          bound by all of these Legal Terms. IF YOU DO NOT AGREE WITH ALL OF
          THESE LEGAL TERMS, THEN YOU ARE EXPRESSLY PROHIBITED FROM USING THE
          SERVICES AND YOU MUST DISCONTINUE USE IMMEDIATELY.
        </p>
        <p className='my-5'>
          Supplemental terms and conditions or documents that may be posted on
          the Services from time to time are hereby expressly incorporated
          herein by reference. We reserve the right, in our sole discretion, to
          make changes or modifications to these Legal Terms at any time and for
          any reason. We will change the 'Last updated' date of these Legal
          Terms, and we may or may not alert you. We encourage you to
          periodically review these Legal Terms to stay informed of updates. You
          will be subject to, and will be deemed to have been made aware of and
          to have accepted, the changes in any revised Legal Terms by your
          continued use of the Services after the date such revised Legal Terms
          are posted.
        </p>
        <p className='my-5'>
          Please be advised that any translations of these Terms provided in
          other languages are solely for your convenience and informational
          purposes. The English language version of these Terms shall
          exclusively govern the legal relationship between you and us. In the
          event of any conflict or inconsistency between the English language
          version and a translated version, the English language version shall
          prevail. All users who are minors in the jurisdiction in which they
          reside (generally under the age of 18) must have the permission of,
          and be directly supervised by, their parent or guardian to use the
          Services. If you are a minor, you must have your parent or guardian
          read and agree to these Legal Terms prior to you using the Services.
        </p>
        <section className='my-16'>
          <h1 className='text-2xl'>TABLE OF CONTENTS</h1>
          <ol className='list-decimal ml-10 my-10'>
            {tableOfContents.map((item, index) => {
              return (
                <li
                  key={index}
                  className='cursor-pointer my-2 text-lg underline'
                >
                  <a href={`#${item.id}`}>{item.heading}</a>
                </li>
              );
            })}
          </ol>
        </section>
        <section id='point144' className='my-10 flex flex-col gap-5'>
          <h1 className='uppercase text-2xl font-bold'>1. OUR SERVICES</h1>
          <p>
            The information provided when using the Services is not intended for
            distribution to or use by any person or entity in any jurisdiction
            or country where such distribution or use would be contrary to law
            or regulation or which would subject us to any registration
            requirement within such jurisdiction or country. Accordingly, those
            persons who choose to access the Services from other locations do so
            on their own initiative and are solely responsible for compliance
            with local laws, if and to the extent local laws are applicable.
          </p>
        </section>
        <section id='point21' className='my-10 flex flex-col gap-5'>
          <h1 className='uppercase text-2xl font-bold'>
            2. INTELLECTUAL PROPERTY RIGHTS
          </h1>
          <h5 className='text-lg'>Our intellectual property</h5>
          <p>
            We are the owner or the licensee of all intellectual property rights
            in our Services, including all source code, databases,
            functionality, software, website designs, audio, video, text,
            photographs, and graphics in the Services (collectively, the
            'Content'), as well as the trademarks, service marks, and logos
            contained therein (the 'Marks').
            <br />
            <br />
            Our Content and Marks are protected by copyright and trademark laws
            (and various other intellectual property rights and unfair
            competition laws) and treaties in India and around the world.
            <br />
            <br />
            The Content and Marks are provided in or through the Services 'AS
            IS' for your personal, non- commercial use only.
            <br />
            <br />
          </p>

          <h5 className='text-lg'>Your use of our Services</h5>
          <p>
            Subject to your compliance with these Legal Terms, including the
            'PROHIBITED ACTIVITIES' section below, we grant you a non-exclusive,
            non-transferable, revocable licence to:
          </p>
          <ul className='list-disc ml-5'>
            <li>access the Services; and</li>
            <li>
              download or print a copy of any portion of the Content to which
              you have properly gained access. solely for your personal,
              non-commercial use.
            </li>
          </ul>

          <p>
            Except as set out in this section or elsewhere in our Legal Terms,
            no part of the Services and no Content or Marks may be copied,
            reproduced, aggregated, republished, uploaded, posted, publicly
            displayed, encoded, translated, transmitted, distributed, sold,
            licensed, or otherwise exploited for any commercial purpose
            whatsoever, without our express prior written permission.
            <br />
            <br />
            If you wish to make any use of the Services, Content, or Marks other
            than as set out in this section or elsewhere in our Legal Terms,
            please address your request to: support@kraverich.com. If we ever
            grant you the permission to post, reproduce, or publicly display any
            part of our Services or Content, you must identify us as the owners
            or licensors of the Services, Content, or Marks and ensure that any
            copyright or proprietary notice appears or is visible on posting,
            reproducing, or displaying our Content.
            <br />
            <br />
            We reserve all rights not expressly granted to you in and to the
            Services, Content, and Marks.
            <br />
            <br />
            Any breach of these Intellectual Property Rights will constitute a
            material breach of our Legal Terms and your right to use our
            Services will terminate immediately.
          </p>

          <h5 className='text-lg'>Your submissions</h5>
          <p>
            Please review this section and the 'PROHIBITED ACTIVITIES' section
            carefully prior to using our Services to understand the (a) rights
            you give us and (b) obligations you have when you post or upload any
            content through the Services.
            <br />
            <br />
            <strong>Submissions:</strong> By directly sending us any question,
            comment, suggestion, idea, feedback, or other information about the
            Services ('Submissions'), you agree to assign to us all intellectual
            property rights in such Submission. You agree that we shall own this
            Submission and be entitled to its unrestricted use and dissemination
            for any lawful purpose, commercial or otherwise, without
            acknowledgment or compensation to you.
          </p>
          <p>
            <strong>You are responsible for what you post or upload:</strong> By
            sending us Submissions through any part of the Services you:
          </p>
          <ul className='list-disc ml-5'>
            <li>
              confirm that you have read and agree with our 'PROHIBITED
              ACTIVITIES' and will not post, send, publish, upload, or transmit
              through the Services any Submission that is illegal, harassing,
              hateful, harmful, defamatory, obscene, bullying, abusive,
              discriminatory, threatening to any person or group, sexually
              explicit, false, inaccurate, deceitful, or misleading;
            </li>
            <li>
              to the extent permissible by applicable law, waive any and all
              moral rights to any such Submission.
            </li>
            <li>
              warrant that any such Submission are original to you or that you
              have the necessary rights and licences to submit such Submissions
              and that you have full authority to grant us the above- mentioned
              rights in relation to your Submissions; and
            </li>
            <li>
              warrant and represent that your Submissions do not constitute
              confidential information. You are solely responsible for your
              Submissions, and you expressly agree to reimburse us for any and
              all losses that we may suffer because of your breach of (a) this
              section, (b) any third party’s intellectual property rights, or
              (c) applicable law.
            </li>
          </ul>
          <p>
            We retain the authority, without prior notice, to take down, block,
            or restrict access to any Content that we deem objectionable,
            whether for a reason or without reason, if it violates the Terms or
            poses harm to the Services or our Customers, solely at our
            discretion. We are not obliged to return any of Your Content to you
            under any circumstances, except as required by applicable law.
          </p>
        </section>
        <section id='point2' className='my-10 flex flex-col gap-5'>
          <h1 className='uppercase text-2xl font-bold'>
            3. USER REPRESENTATIONS
          </h1>
          <p>
            By using the Services, you represent and warrant that: (1) all
            registration information you submit will be true, accurate, current,
            and complete; (2) you will maintain the accuracy of such information
            and promptly update such registration information as necessary; (3)
            you have the legal capacity and you agree to comply with these Legal
            Terms; (4) you are not under the age of 13; (5) you are not a minor
            in the jurisdiction in which you reside, or if a minor, you have
            received parental permission to use the Services; (6) you will not
            access the Services through automated or non-human means, whether
            through a bot, script or otherwise; (7) you will not use the
            Services for any illegal or unauthorised purpose; and (8) your use
            of the Services will not violate any applicable law or regulation.
            <br />
            <br />
            If you provide any information that is untrue, inaccurate, not
            current, or incomplete, we have the right to suspend or terminate
            your account and refuse any and all current or future use of the
            Services (or any portion thereof).
          </p>
        </section>
        <section id='point3' className='my-10 flex flex-col gap-5'>
          <h1 className='uppercase text-2xl font-bold'>4. USER REGISTRATION</h1>
          <p>
            You may be required to register to use the Services. You agree to
            keep your password confidential and will be responsible for all use
            of your account and password. We reserve the right to remove,
            reclaim, or change a username you select if we determine, in our
            sole discretion, that such username is inappropriate, obscene, or
            otherwise objectionable.
          </p>
        </section>
        <section id='point4' className='my-10 flex flex-col gap-5'>
          <h1 className='uppercase text-2xl font-bold'>5. PRODUCTS</h1>
          <p>
            We make every effort to display as accurately as possible the
            colours, features, specifications, and details of the products
            available on the Services. However, we do not guarantee that the
            colours, features, specifications, and details of the products will
            be accurate, complete, reliable, current, or free of other errors,
            and your electronic display may not accurately reflect the actual
            colours and details of the products. All products are subject to
            availability, and we cannot guarantee that items will be in stock.
            We reserve the right to discontinue any products at any time for any
            reason. Prices for all products are subject to change.
          </p>
        </section>
        <section id='point5' className='my-10 flex flex-col gap-5'>
          <h1 className='uppercase text-2xl font-bold'>
            6. PURCHASES AND PAYMENT
          </h1>
          We accept the following forms of payment:
          <ul className='ml-10 list-disc'>
            <li>Visa</li>
            <li>Mastercard</li>
            <li>American Express - UPI</li>
            <li>RuPay</li>
            <li>Cash</li>
          </ul>
          <p>
            You agree to provide current, complete, and accurate purchase and
            account information for all purchases made via the Services. You
            further agree to promptly update account and payment information,
            including email address, payment method, and payment card expiration
            date, so that we can complete your transactions and contact you as
            needed. Sales tax will be added to the price of purchases as deemed
            required by us. We may change prices at any time. All payments shall
            be in Indian Rupees.
            <br />
            <br />
            You agree to pay all charges at the prices then in effect for your
            purchases and any applicable shipping fees, and you authorise us to
            charge your chosen payment provider for any such amounts upon
            placing your order. We reserve the right to correct any errors or
            mistakes in pricing, even if we have already requested or received
            payment.
            <br />
            <br />
            We reserve the right to refuse any order placed through the
            Services. We may, in our sole discretion, limit or cancel quantities
            purchased per person, per household, or per order. These
            restrictions may include orders placed by or under the same customer
            account, the same payment method, and/or orders that use the same
            billing or shipping address. We reserve the right to limit or
            prohibit orders that, in our sole judgement, appear to be placed by
            dealers, resellers, or distributors.
          </p>
        </section>
        <section id='point6' className='my-10 flex flex-col gap-5'>
          <h1 className='uppercase text-2xl font-bold'>
            7. CANCELLATION AND REFUND POLICY
          </h1>
          <p>Cancellation and Refund Policy for KraveRich Retail Pvt Ltd:</p>
          <ul className='list-decimal'>
            <li>
              By placing an order, you acknowledge that any cancellation attempt
              or failure to fulfil your order due to reasons such as providing
              incorrect details, being unreachable, or unresponsive, constitutes
              a breach of your authorization granted to KraveRich. This breach
              obligates you to pay liquidated damages equivalent to the order
              value. You authorize KraveRich to collect such damages through
              methods it deems fit, including deducting from future orders.
            </li>
            <li>
              In instances where KraveRich is unable to process or cancels an
              order due to technical issues, item unavailability, or other
              reasons attributable to KraveRich, the Restaurant Partner/Store,
              or the Delivery Partner, no cancellation charges will be applied.
              If payment has already been processed and a refund is due, the
              amount will be reimbursed to you.
            </li>
            <li>
              No replacements, refunds, or resolutions will be provided without
              the consent of the Restaurant Partner(s)/Store(s).
            </li>
            <li>
              Any complaints regarding the order, such as food spillage, foreign
              objects, wrong deliveries, or poor quality, require proof before
              any resolution is offered.
            </li>
            <li>
              Refunds are not granted if instructions accompanying the order are
              not followed as intended. Instructions are executed by the
              Restaurant Partner/Store to the best of their ability.
            </li>
            <li>
              Refunds are processed in the same manner as received, unless
              provided in the form of credits. Refund timing may vary depending
              on the policies and mechanisms of respective banks and payment
              systems.
            </li>
          </ul>
        </section>
        <section id='point7' className='my-10 flex flex-col gap-5'>
          <h1 className='uppercase text-2xl font-bold'>
            8. PROHIBITED ACTIVITIES
          </h1>
          <p>
            You may not access or use the Services for any purpose other than
            that for which we make the Services available. The Services may not
            be used in connection with any commercial endeavours except those
            that are specifically endorsed or approved by us.
          </p>
          <p>As a user of the Services, you agree not to:</p>
          <ul className='ml-10 list-disc'>
            <li>
              Systematically retrieve data or other content from the Services to
              create or compile, directly or indirectly, a collection,
              compilation, database, or directory without written permission
              from us.
            </li>
            <li>
              Trick, defraud, or mislead us and other users, especially in any
              attempt to learn sensitive account information such as user
              passwords.
            </li>
            <li>
              Circumvent, disable, or otherwise interfere with security-related
              features of the Services, including features that prevent or
              restrict the use or copying of any Content or enforce limitations
              on the use of the Services and/or the Content contained therein.
            </li>
            <li>
              Disparage, tarnish, or otherwise harm, in our opinion, us and/or
              the Services.
            </li>
            <li>
              Use any information obtained from the Services in order to harass,
              abuse, or harm another person.
            </li>
            <li>
              Make improper use of our support services or submit false reports
              of abuse or misconduct.
            </li>
            <li>
              Use the Services in a manner inconsistent with any applicable laws
              or regulations.
            </li>
            <li>
              Engage in unauthorised framing of or linking to the Services.
            </li>
            <li>
              Upload or transmit (or attempt to upload or to transmit) viruses,
              Trojan horses, or other material, including excessive use of
              capital letters and spamming (continuous posting of repetitive
              text), that interferes with any party’s uninterrupted use and
              enjoyment of the Services or modifies, impairs, disrupts, alters,
              or interferes with the use, features, functions, operation, or
              maintenance of the Services.
            </li>
            <li>
              Engage in any automated use of the system, such as using scripts
              to send comments or messages,or using any data mining, robots, or
              similar data gathering and extraction tools.
            </li>
            <li>
              Delete the copyright or other proprietary rights notice from any
              Content.
            </li>
            <li>
              Attempt to impersonate another user or person or use the username
              of another user.
            </li>
            <li>
              Upload or transmit (or attempt to upload or to transmit) any
              material that acts as a passive or active information collection
              or transmission mechanism, including without limitation, clear
              graphics interchange formats ('gifs'), 1×1 pixels, web bugs,
              cookies, or other similar devices (sometimes referred to as
              'spyware' or 'passive collection mechanisms' or 'PCMs').
            </li>
            <li>
              Interfere with, disrupt, or create an undue burden on the Services
              or the networks or services connected to the Services.
            </li>
            <li>
              Harass, annoy, intimidate, or threaten any of our employees or
              agents engaged in providing any portion of the Services to you.
            </li>
            <li>
              Attempt to bypass any measures of the Services designed to prevent
              or restrict access to the Services, or any portion of the
              Services.
            </li>
            <li>
              Copy or adapt the Services' software, including but not limited to
              Flash, PHP, HTML, JavaScript, or other code.
            </li>
            <li>
              Except as permitted by applicable law, decipher, decompile,
              disassemble, or reverse engineer any of the software comprising or
              in any way making up a part of the Services.
            </li>
            <li>
              Except as may be the result of standard search engine or Internet
              browser usage, use, launch, develop, or distribute any automated
              system, including without limitation, any spider, robot, cheat
              utility, scraper, or offline reader that accesses the Services, or
              use or launch any unauthorised script or other software.
            </li>
            <li>
              Use a buying agent or purchasing agent to make purchases on the
              Services.
            </li>
            <li>
              Make any unauthorised use of the Services, including collecting
              usernames and/or email addresses of users by electronic or other
              means for the purpose of sending unsolicited email, or creating
              user accounts by automated means or under false pretences.
            </li>
            <li>
              Use the Services as part of any effort to compete with us or
              otherwise use the Services and/or the Content for any
              revenue-generating endeavour or commercial enterprise.
            </li>
            <li>Sell or otherwise transfer your profile.</li>
            <li>
              Use the Services to advertise or offer to sell goods and services.
            </li>
          </ul>
        </section>
        <section id='point8' className='my-10 flex flex-col gap-5'>
          <h1 className='uppercase text-2xl font-bold'>
            9. USER GENERATED CONTRIBUTIONS
          </h1>
          <p>
            We may provide you with the opportunity to create, submit, post,
            display, transmit, perform, publish, distribute, or broadcast
            content and materials to us or on the Services, including but not
            limited to text, writings, video, audio, photographs, graphics,
            comments, suggestions, or personal information or other material
            (collectively, 'Contributions'). Contributions may be viewable by
            other users of the Services and through third-party websites. As
            such, any Contributions you transmit may be treated in accordance
            <br />
            <br />
            with the Services' Privacy Policy. When you create or make available
            any Contributions, you thereby represent and warrant that:
          </p>
          <ul className='ml-10 list-disc'>
            <li>
              The creation, distribution, transmission, public display, or
              performance, and the accessing, downloading, or copying of your
              Contributions do not and will not infringe the proprietary rights,
              including but not limited to the copyright, patent, trademark,
              trade secret, or moral rights of any third party.
            </li>
            <li>
              You are the creator and owner of or have the necessary licences,
              rights, consents, releases, and permissions to use and to
              authorise us, the Services, and other users of the Services to use
              your Contributions in any manner contemplated by the Services and
              these Legal Terms.
            </li>
            <li>
              You have the written consent, release, and/or permission of each
              and every identifiable individual person in your Contributions to
              use the name or likeness of each and every such identifiable
              individual person to enable inclusion and use of your
              Contributions in any manner contemplated by the Services and these
              Legal Terms.
            </li>
            <li>
              • Your Contributions are not false, inaccurate, or misleading.
            </li>
            <li>
              Your Contributions are not unsolicited or unauthorised
              advertising, promotional materials, pyramid schemes, chain
              letters, spam, mass mailings, or other forms of solicitation.
            </li>
            <li>
              Your Contributions are not obscene, lewd, lascivious, filthy,
              violent, harassing, libellous, slanderous, or otherwise
              objectionable (as determined by us).
            </li>
            <li>
              Your Contributions do not ridicule, mock, disparage, intimidate,
              or abuse anyone.
            </li>
            <li>
              Your Contributions are not used to harass or threaten (in the
              legal sense of those terms) any other person and to promote
              violence against a specific person or class of people.
            </li>
            <li>
              Your Contributions do not violate any applicable law, regulation,
              or rule.
            </li>
            <li>
              Your Contributions do not violate the privacy or publicity rights
              of any third party.
            </li>
            <li>
              Your Contributions do not violate any applicable law concerning
              child pornography, or otherwise intended to protect the health or
              well-being of minors.
            </li>
            <li>
              Your Contributions do not include any offensive comments that are
              connected to race, national origin, gender, sexual preference, or
              physical handicap.
            </li>
            <li>
              Your Contributions do not otherwise violate, or link to material
              that violates, any provision of these Legal Terms, or any
              applicable law or regulation.
            </li>
          </ul>
          <p>
            Any use of the Services in violation of the foregoing violates these
            Legal Terms and may result in, among other things, termination or
            suspension of your rights to use the Services.
          </p>
        </section>
        <section id='point9' className='my-10 flex flex-col gap-5'>
          <h1 className='uppercase text-2xl font-bold'>
            10. CONTRIBUTION LICENCE
          </h1>
          <p>
            You and Services agree that we may access, store, process, and use
            any information and personal data that you provide following the
            terms of the Privacy Policy and your choices (including settings).
            <br />
            <br />
            By submitting suggestions or other feedback regarding the Services,
            you agree that we can use and share such feedback for any purpose
            without compensation to you.
            <br />
            <br />
            We do not assert any ownership over your Contributions. You retain
            full ownership of all of your Contributions and any intellectual
            property rights, or other proprietary rights associated with your
            Contributions. We are not liable for any statements or
            representations in your Contributions provided
            <br />
            <br />
            by you in any area on the Services. You are solely responsible for
            your Contributions to the Services, and you expressly agree to
            exonerate us from any and all responsibility and to refrain from any
            legal action against us regarding your Contributions.
          </p>
        </section>
        <section id='point10' className='my-10 flex flex-col gap-5'>
          <h1 className='uppercase text-2xl font-bold'>
            11. MOBILE APPLICATION LICENCE
          </h1>
          <h5>Use Licence</h5>
          <p>
            If you access the Services via the App, then we grant you a
            revocable, non-exclusive, non-transferable, limited right to install
            and use the App on wireless electronic devices owned or controlled
            by you, and to access and use the App on such devices strictly in
            accordance with the terms and conditions of this mobile application
            licence contained in these Legal Terms. You shall not: <br />
            (1) except as permitted by applicable law, decompile, reverse
            engineer, disassemble, attempt to derive the source code of, or
            decrypt the App;
            <br /> (2) make any modification, adaptation, improvement,
            enhancement, translation, or derivative work from the App; <br />
            (3) violate any applicable laws, rules, or regulations in connection
            with your access or use of the App;
            <br /> (4) remove, alter, or obscure any proprietary notice
            (including any notice of copyright or trademark) posted by us or the
            licensors of the App;
            <br />
            (5) use the App for any revenue- generating endeavour, commercial
            enterprise, or other purpose for which it is not designed or
            intended;
            <br />
            (6) make the App available over a network or other environment
            permitting access or use by multiple devices or users at the same
            time; <br />
            (7) use the App for creating a product, service, or software that
            is, directly or indirectly, competitive with or in any way a
            substitute for the App;
            <br /> (8) use the App to send automated queries to any website or
            to send any unsolicited commercial email; or
            <br /> (9) use any proprietary information or any of our interfaces
            or our other intellectual property in the design, development,
            manufacture, licensing, or distribution of any applications,
            accessories, or devices for use with the App.
          </p>
          <h5>Apple and Android Devices</h5>
          <p>
            The following terms apply when you use the App obtained from either
            the Apple Store or Google Play (each an 'App Distributor') to access
            the Services: <br />
            (1) the licence granted to you for our App is limited to a
            non-transferable licence to use the application on a device that
            utilises the Apple iOS or Android operating systems, as applicable,
            and in accordance with the usage rules set forth in the applicable
            App Distributor’s terms of service; <br />
            (2) we are responsible for providing any maintenance and support
            services with respect to the App as specified in the terms and
            conditions of this mobile application licence contained in these
            Legal Terms or as otherwise required under applicable law, and you
            acknowledge that each App Distributor has no obligation whatsoever
            to furnish any maintenance and support services with respect to the
            App; <br />
            (3) in the event of any failure of the App to conform to any
            applicable warranty, you may notify the applicable App Distributor,
            and the App Distributor, in accordance with its terms and policies,
            may refund the purchase price, if any, paid for the App, and to the
            maximum extent permitted by applicable law, the App Distributor will
            have no other warranty obligation whatsoever with respect to the
            App; <br />
            (4) you represent and warrant that <br />
            (i) you are not located
            <br />
            <br />
            in a country that is subject to a Government of India embargo, or
            that has been designated by the Government of India as a 'terrorist
            supporting' country and <br />
            (ii) you are not listed on any Government of India list of
            prohibited or restricted parties; <br />
            (5) you must comply with applicable third-party terms of agreement
            when using the App, e.g. if you have a VoIP application, then you
            must not be in violation of their wireless data service agreement
            when using the App; and <br />
            (6) you acknowledge and agree that the App Distributors are
            third-party beneficiaries of the terms and conditions in this mobile
            application licence contained in these Legal Terms, and that each
            App Distributor will have the right (and will be deemed to have
            accepted the right) to enforce the terms and conditions in this
            mobile application licence contained in these Legal Terms against
            you as a third-party beneficiary thereof.
          </p>
        </section>
        <section id='point11' className='my-10 flex flex-col gap-5'>
          <h1 className='uppercase text-2xl font-bold'>
            12. SERVICES MANAGEMENT
          </h1>
          <p>
            We reserve the right, but not the obligation, to: <br />
            (1) monitor the Services for violations of these Legal Terms; <br />
            (2) take appropriate legal action against anyone who, in our sole
            discretion, violates the law or these Legal Terms, including without
            limitation, reporting such user to law enforcement authorities;{' '}
            <br />
            (3) in our sole discretion and without limitation, refuse, restrict
            access to, limit the availability of, or disable (to the extent
            technologically feasible) any of your Contributions or any portion
            thereof; <br />
            (4) in our sole discretion and without limitation, notice, or
            liability, to remove from the Services or otherwise disable all
            files and content that are excessive in size or are in any way
            burdensome to our systems; and <br />
            (5) otherwise manage the Services in a manner designed to protect
            our rights and property and to facilitate the proper functioning of
            the Services.
          </p>
        </section>
        <section id='point12' className='my-10 flex flex-col gap-5'>
          <h1 className='uppercase text-2xl font-bold'>
            13. PRIVACY POLICY and CONTENT GUIDELINES
          </h1>
          <p>
            We care about data privacy and security. Please review our Privacy
            Policy: https://www.privacy.kraverich.com. By using the Services,
            you agree to be bound by our Privacy Policy, which is incorporated
            into these Legal Terms. Please be advised the Services are hosted in
            India. If you access the Services from any other region of the world
            with laws or other requirements governing personal data collection,
            use, or disclosure that differ from applicable laws in India, then
            through your continued use of the Services, you are transferring
            your data to India, and you expressly consent to have your data
            transferred to and processed in India.
            <br />
            <br />
            Your continued use constitutes your affirmation that you have read,
            grasped, and consented to the stipulated Guidelines and Policies
            pertaining to Content.
          </p>
        </section>{' '}
        <section id='point13' className='my-10 flex flex-col gap-5'>
          <h1 className='uppercase text-2xl font-bold'>
            14. TERM AND TERMINATION
          </h1>
          <p>
            These Legal Terms shall remain in full force and effect while you
            use the Services. WITHOUT LIMITING ANY OTHER PROVISION OF THESE
            LEGAL TERMS, WE RESERVE THE RIGHT TO, IN OUR SOLE DISCRETION AND
            WITHOUT NOTICE OR LIABILITY, DENY ACCESS TO AND USE OF THE SERVICES
            (INCLUDING BLOCKING CERTAIN IP ADDRESSES), TO ANY PERSON FOR ANY
            REASON OR FOR NO REASON, INCLUDING WITHOUT LIMITATION FOR BREACH OF
            ANY REPRESENTATION, WARRANTY, OR COVENANT CONTAINED IN THESE LEGAL
            TERMS OR OF ANY APPLICABLE LAW OR REGULATION. WE MAY TERMINATE YOUR
            USE OR PARTICIPATION IN THE SERVICES OR DELETE YOUR ACCOUNT AND ANY
            CONTENT OR INFORMATION THAT YOU POSTED AT ANY TIME, WITHOUT WARNING,
            IN OUR SOLE DISCRETION.
            <br />
            <br />
            If we terminate or suspend your account for any reason, you are
            prohibited from registering and creating a new account under your
            name, a fake or borrowed name, or the name of any third party, even
            if you may be acting on behalf of the third party. In addition to
            terminating or suspending your account, we reserve the right to take
            appropriate legal action, including without limitation pursuing
            civil, criminal, and injunctive redress.
          </p>
        </section>{' '}
        <section id='point14' className='my-10 flex flex-col gap-5'>
          <h1 className='uppercase text-2xl font-bold'>
            15. MODIFICATIONS AND INTERRUPTIONS
          </h1>
          <p>
            We reserve the right to change, modify, or remove the contents of
            the Services at any time or for any reason at our sole discretion
            without notice. However, we have no obligation to update any
            information on our Services. We also reserve the right to modify or
            discontinue all or part of the Services without notice at any time.
            We will not be liable to you or any third party for any
            modification, price change, suspension, or discontinuance of the
            Services.
            <br />
            <br />
            We cannot guarantee the Services will be available at all times. We
            may experience hardware, software, or other problems or need to
            perform maintenance related to the Services, resulting in
            interruptions, delays, or errors. We reserve the right to change,
            revise, update, suspend, discontinue, or otherwise modify the
            Services at any time or for any reason without notice to you. You
            agree that we have no liability whatsoever for any loss, damage, or
            inconvenience caused by your inability to access or use the Services
            during any downtime or discontinuance of the Services. Nothing in
            these Legal Terms will be construed to obligate us to maintain and
            support the Services or to supply any corrections, updates, or
            releases in connection therewith.
          </p>
        </section>{' '}
        <section id='point15' className='my-10 flex flex-col gap-5'>
          <h1 className='uppercase text-2xl font-bold'>16. GOVERNING LAW</h1>
          <p>
            These Legal Terms shall be governed by and defined following the
            laws of India. KraveRich Retail Private Limited and yourself
            irrevocably consent that the courts of India shall have exclusive
            jurisdiction to resolve any dispute which may arise in connection
            with these Legal Terms.
          </p>
        </section>{' '}
        <section id='point16' className='my-10 flex flex-col gap-5'>
          <h1 className='uppercase text-2xl font-bold'>
            17. DISPUTE RESOLUTION
          </h1>
          <p>
            These Terms of Service shall be governed in accordance with the laws
            of India. All disputes related to the Services will be subject to
            the exclusive jurisdiction of courts of Bangalore only. KraveRich
            Retail Private Limited shall also maintain the right to bring
            proceedings as to the substance of the matter in the courts of the
            country where you reside or, if these Legal Terms are entered into
            in the course of your trade or profession, the state of your
            principal place of business.
          </p>
        </section>{' '}
        <section id='point17' className='my-10 flex flex-col gap-5'>
          <h1 className='uppercase text-2xl font-bold'>18. CORRECTIONS</h1>
          <p>
            There may be information on the Services that contains typographical
            errors, inaccuracies, or omissions, including descriptions, pricing,
            availability, and various other information. We reserve the right to
            correct any errors, inaccuracies, or omissions and to change or
            update the information on the Services at any time, without prior
            notice.
          </p>
        </section>{' '}
        <section id='point18' className='my-10 flex flex-col gap-5'>
          <h1 className='uppercase text-2xl font-bold'>19. DISCLAIMER</h1>
          <p>
            THE SERVICES ARE PROVIDED ON AN AS-IS AND AS-AVAILABLE BASIS. YOU
            AGREE THAT YOUR USE OF THE SERVICES WILL BE AT YOUR SOLE RISK. TO
            THE FULLEST EXTENT PERMITTED BY LAW, WE DISCLAIM ALL WARRANTIES,
            EXPRESS OR IMPLIED, IN CONNECTION WITH THE SERVICES AND YOUR USE
            THEREOF, INCLUDING, WITHOUT LIMITATION, THE IMPLIED WARRANTIES OF
            MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND
            NON-INFRINGEMENT. WE MAKE NO WARRANTIES OR REPRESENTATIONS ABOUT THE
            ACCURACY OR COMPLETENESS OF THE SERVICES' CONTENT OR THE CONTENT OF
            ANY WEBSITES OR MOBILE APPLICATIONS LINKED TO THE SERVICES AND WE
            WILL ASSUME NO LIABILITY OR RESPONSIBILITY FOR ANY (1) ERRORS,
            MISTAKES, OR INACCURACIES OF CONTENT AND MATERIALS, (2) PERSONAL
            INJURY OR PROPERTY DAMAGE, OF ANY NATURE WHATSOEVER, RESULTING FROM
            YOUR ACCESS TO AND USE OF THE SERVICES, (3) ANY UNAUTHORISED ACCESS
            TO OR USE OF OUR SECURE SERVERS AND/OR ANY AND ALL PERSONAL
            INFORMATION AND/OR FINANCIAL INFORMATION STORED THEREIN, (4) ANY
            INTERRUPTION OR CESSATION OF TRANSMISSION TO OR FROM THE SERVICES,
            (5) ANY BUGS, VIRUSES, TROJAN HORSES, OR THE LIKE WHICH MAY BE
            TRANSMITTED TO OR THROUGH THE SERVICES BY ANY THIRD PARTY, AND/OR
            (6) ANY ERRORS OR OMISSIONS IN ANY CONTENT AND MATERIALS OR FOR ANY
            LOSS OR DAMAGE OF ANY KIND INCURRED AS A RESULT OF THE USE OF ANY
            CONTENT POSTED, TRANSMITTED, OR OTHERWISE MADE AVAILABLE VIA THE
            SERVICES. WE DO NOT WARRANT, ENDORSE, GUARANTEE, OR ASSUME
            RESPONSIBILITY FOR ANY PRODUCT OR SERVICE ADVERTISED OR OFFERED BY A
            THIRD PARTY THROUGH THE SERVICES, ANY HYPERLINKED WEBSITE, OR ANY
            WEBSITE OR MOBILE APPLICATION FEATURED IN ANY BANNER OR OTHER
            ADVERTISING, AND WE WILL NOT BE A PARTY TO OR IN ANY WAY BE
            RESPONSIBLE FOR MONITORING ANY TRANSACTION BETWEEN YOU AND ANY
            THIRD-PARTY PROVIDERS OF PRODUCTS OR SERVICES. AS WITH THE PURCHASE
            OF A PRODUCT OR SERVICE THROUGH ANY MEDIUM OR IN ANY ENVIRONMENT,
            YOU SHOULD USE YOUR BEST JUDGEMENT AND EXERCISE CAUTION WHERE
            APPROPRIATE
          </p>
        </section>{' '}
        <section id='point19' className='my-10 flex flex-col gap-5'>
          <h1 className='uppercase text-2xl font-bold'>
            20. LIMITATIONS OF LIABILITY
          </h1>
          <p>
            IN NO EVENT WILL WE OR OUR DIRECTORS, EMPLOYEES, OR AGENTS BE LIABLE
            TO YOU OR ANY THIRD PARTY FOR ANY DIRECT, INDIRECT, CONSEQUENTIAL,
            EXEMPLARY, INCIDENTAL, SPECIAL, OR PUNITIVE DAMAGES, INCLUDING LOST
            PROFIT, LOST REVENUE, LOSS OF DATA, OR OTHER DAMAGES ARISING FROM
            YOUR USE OF THE SERVICES, EVEN IF WE HAVE BEEN ADVISED OF THE
            POSSIBILITY OF SUCH DAMAGES. NOTWITHSTANDING ANYTHING TO THE
            CONTRARY CONTAINED HEREIN, OUR LIABILITY TO YOU FOR ANY CAUSE
            WHATSOEVER AND REGARDLESS OF THE FORM OF THE ACTION, WILL AT ALL
            TIMES BE LIMITED TO THE AMOUNT PAID, IF ANY, BY YOU TO US DURING THE
            ONE (1) DAY PERIOD PRIOR TO ANY CAUSE OF ACTION ARISING. YOU MAY
            HAVE ADDITIONAL RIGHTS IF PROVIDED BY THE LAWS OF INDIA.
            <br />
            <br />
            KRAVERICH AS A PLATFORM, PROVIDES GENERIC NUTRITION ADVICE SUCH AS
            NUTRITION TRACKER AND DIET RECOMMENDATIONS, BASED ON YOUR
            PREFERENCES AND PROFILE. NEITHER KRAVERICH, NOR THE NUTRITIONISTS
            ARE LIABLE TO YOU OR ANY THIRD-PARTY FOR INHERENT RISKS ASSOCIATED
            WITH THE USE OF THESE SERVICES, SUCH AS POTENTIAL ALLERGIC REACTIONS
            OR INDIVIDUAL HEALTH VARIATIONS. KRA VERICH DOES NOT PROVIDE ANY
            GUARANTEE/ASSURANCE REGARDING YOUR ACHIEVING OF YOUR GOALS. UPON
            CONFIRMING YOUR ORDER ON OUR PLATFORM, THE DESIGNATED RESTAURANT
            PARTNER OR STORE MAY EXECUTE THE DELIVERY THEMSELVES, OR WE MAY
            FACILITATE THE DELIVERY PROCESS THROUGH A CONTRACTED THIRD-PARTY
            DELIVERY SERVICE PROVIDER ("DELIVERY PARTNERS"). IN BOTH SCENARIOS,
            OUR ROLE IS STRICTLY THAT OF AN INTERMEDIARY, CONNECTING YOU WITH
            THE DELIVERY PARTNERS (IF APPLICABLE) OR DIRECTLY WITH THE
            RESTAURANT PARTNER/STORE. THIS SHALL CONSTITUTE A SERVICE CONTRACT,
            AS DEFINED BY THE CONSUMER PROTECTION ACT, 2019 (OR ANY SUCCESSOR
            LEGISLATION), SOLELY BETWEEN YOU AND THE DELIVERY PARTNER. BY LAW,
            KRAVERICH IS NOT A PARTY TO THIS CONTRACT. FOR CLARITY, KRAVERICH
            DOES NOT PROVIDE DELIVERY OR LOGISTICS SERVICES ITSELF.
            <br />
            <br /> OUR ROLE IS LIMITED TO FACILITATING THE DELIVERY OF FOOD,
            BEVERAGES, OR OTHER PRODUCTS ORDERED THROUGH THE KRAVERICH PLATFORM
            BY CONNECTING YOU WITH THE DELIVERY PARTNER, RESTAURANT PARTNER, OR
            STORE, AS APPLICABLE. THEREFORE, KRAVERICH EXPRESSLY DISCLAIMS ANY
            AND ALL LIABILITY ARISING FROM THE ACTS OR OMISSIONS OF THE
            RESTAURANT PARTNER/STORE(S). THIS INCLUDES, BUT IS NOT LIMITED TO,
            DEFICIENCIES IN SERVICE, INCORRECT OR MISMATCHED ORDERS, QUALITY
            ISSUES, PRICING ERRORS, DISCREPANCIES IN QUANTITY, IMPROPER
            PACKAGING, DELAYS IN ORDER PREPARATION OR DELIVERY, AND ANY OTHER
            RELATED CONCERNS.
          </p>
        </section>{' '}
        <section id='point20' className='my-10 flex flex-col gap-5'>
          <h1 className='uppercase text-2xl font-bold'>21. INDEMNIFICATION</h1>
          <p>
            You agree to defend, indemnify, and hold us harmless, including our
            subsidiaries, affiliates, and all of our respective officers,
            agents, partners, and employees, from and against any loss, damage,
            liability, claim, or demand, including reasonable attorneys’ fees
            and expenses, made by any third party due to or arising out of: (1)
            use of the Services; (2) breach of these Legal Terms; (3) any breach
            of your representations and warranties set forth in these Legal
            Terms; (4) your violation of the rights of a third party, including
            but not limited to intellectual property rights; or (5) any overt
            harmful act toward any other user of the Services with whom you
            connected via the Services. Notwithstanding the foregoing, we
            reserve the right, at your expense, to assume the exclusive defence
            and control of any matter for which you are required to indemnify
            us, and you agree to cooperate, at your expense, with our defence of
            such claims. We will use reasonable efforts to notify you of any
            such claim, action, or proceeding which is subject to this
            indemnification upon becoming aware of it.
          </p>
        </section>{' '}
        <section id='point21' className='my-10 flex flex-col gap-5'>
          <h1 className='uppercase text-2xl font-bold'>22. USER DATA</h1>
          <p>
            We will maintain certain data that you transmit to the Services for
            the purpose of managing the performance of the Services, as well as
            data relating to your use of the Services. Although we perform
            regular routine backups of data, you are solely responsible for all
            data that you transmit or that relates to any activity you have
            undertaken using the Services. You agree that we shall have no
            liability to you for any loss or corruption of any such data, and
            you hereby waive any right of action against us arising from any
            such loss or corruption of such data.
          </p>
        </section>{' '}
        <section id='point22' className='my-10 flex flex-col gap-5'>
          <h1 className='uppercase text-2xl font-bold'>
            23. ELECTRONIC COMMUNICATIONS, TRANSACTIONS, AND SIGNATURES
          </h1>
          <p>
            Visiting the Services, sending us emails, and completing online
            forms constitute electronic communications. You consent to receive
            electronic communications, and you agree that all agreements,
            notices, disclosures, and other communications we provide to you
            electronically, via email and on the Services, satisfy any legal
            requirement that such communication be in writing. YOU HEREBY AGREE
            TO THE USE OF ELECTRONIC SIGNATURES, CONTRACTS, ORDERS, AND OTHER
            RECORDS, AND TO ELECTRONIC DELIVERY OF NOTICES, POLICIES, AND
            RECORDS OF TRANSACTIONS INITIATED OR COMPLETED BY US OR VIA THE
            SERVICES. You hereby waive any rights or requirements under any
            statutes, regulations, rules, ordinances, or other laws in any
            jurisdiction which require an original signature or delivery or
            retention of non-electronic records, or to payments or the granting
            of credits by any means other than electronic means.
          </p>
        </section>{' '}
        <section id='point23' className='my-10 flex flex-col gap-5'>
          <h1 className='uppercase text-2xl font-bold'>24. MISCELLANEOUS</h1>
          <p>
            These Legal Terms and any policies or operating rules posted by us
            on the Services or in respect to the Services constitute the entire
            agreement and understanding between you and us. Our failure to
            exercise or enforce any right or provision of these Legal Terms
            shall not operate as a waiver of such right or provision. These
            Legal Terms operate to the fullest extent permissible by law. We may
            assign any or all of our rights and obligations to others at any
            time. We shall not be responsible or liable for any loss, damage,
            delay, or failure to act caused by any cause beyond our reasonable
            control. If any provision or part of a provision of these Legal
            Terms is determined to be unlawful, void, or unenforceable, that
            provision or part of the provision is deemed severable from these
            Legal Terms and does not affect the validity and enforceability of
            any remaining provisions. There is no joint venture, partnership,
            employment or agency relationship created between you and us as a
            result of these Legal Terms or use of the Services. You agree that
            these Legal Terms will not be construed against us by virtue of
            having drafted them. You hereby waive any and all defences you may
            have based on the electronic form of these Legal Terms and the lack
            of signing by the parties hereto to execute these Legal Terms.
          </p>
        </section>{' '}
        <section id='point24' className='my-10 flex flex-col gap-5'>
          <h1 className='uppercase text-2xl font-bold'>25. CONTACT US</h1>
          <p>
            In order to resolve a complaint regarding the Services or to receive
            further information regarding use of the Services, please contact us
            at:
          </p>
          <address>
            <strong>Legal Entity Name:</strong> KraveRich Retail Private Limited
            <br />
            <br />
            <strong>Corporate Address:</strong>
            <br />
            Lift Off, CIIE-IIMA IIM Ahmedabad, Vastrapur, Ahmedabad Ahmedabad,
            <br />
            Gujarat 380015 India Registered Address: #9 Halagevaderahalli, 160
            <br />
            Raja Rajeshwari Nagar, Bengaluru, Karnataka - 560098
          </address>
          <strong>Contact details: support@kraverich.com</strong>
        </section>
      </div>
    </div>
  );
};

export default TermsAndConditions;
