import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import Dashboard from './Dashboard';
import './index.scss';

const queryClient = new QueryClient();

const root = createRoot(document.getElementById('root'));

root.render(
  <StrictMode>
    <QueryClientProvider client={queryClient}>
      <Router>
        <Dashboard />
      </Router>
    </QueryClientProvider>
  </StrictMode>,
);
