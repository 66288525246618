import NotifyModal from 'pages/Modals/NotifyModal/NotifyModal';
import { useState } from 'react';
import {
  FaChevronDown,
  FaChevronUp,
  FaInstagram,
  FaLinkedin,
} from 'react-icons/fa';
import { HiOutlineMail } from 'react-icons/hi';
import { LuPhone } from 'react-icons/lu';
import { Link } from 'react-router-dom';
import { trackEvent } from 'utils/trackevent';
import GooglePlay from '../../images/landing/appstore.svg';
import AppStore from '../../images/landing/play.svg';
import logo2 from '../../images/logo-white.png';
import '../LandingPage/LandingPage.scss';

const Footer = () => {
  const [chevronState, setChevronState] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const openPlayStore = () => {
    const url =
      'https://play.google.com/store/apps/details?id=com.kraverich.app';
    window.open(url, '_blank');
  };
  const openAppleStore = () => {
    const url = 'https://apps.apple.com/in/app/kraverich/id6575390715';
    window.open(url, '_blank');
  };

  return (
    <>
      <footer className='w-full bg-dark-purple flex md:flex-row flex-col items-center justify-between text-white md:px-28 px-10 md:py-5 py-6 gap-5'>
        <div className='flex md:flex-row flex-col md:items-start gap-5 py-10 w-full'>
          <div className='md:border-r-[1px] border-gray-500 pr-4'>
            <img src={logo2} alt='Site Logo' width={150} />
            <h1 className='mt-3 subtitle'>
              Eat Consciously, Order Effortlessly
            </h1>
            <p className='mt-10  md:block hidden text-[#8f85a9]'>
              &copy; 2024 KraveRich Retail Pvt Ltd
            </p>
            <p className=' md:block hidden text-[#8f85a9]'>
              All rights reserved.
            </p>
          </div>
          <div className='flex flex-col justify-start font-medium gap-2 subtitle'>
            <Link to={'/'}>Home</Link>
            <Link to={'/about'}>About</Link>
            <Link to={'/partner'}>Restaurant Partner</Link>
            <span
              onClick={() => setChevronState(!chevronState)}
              className='flex items-center gap-4 duration-300 cursor-pointer'
            >
              T&C and Policies{' '}
              {chevronState ? <FaChevronUp /> : <FaChevronDown />}{' '}
            </span>
            {chevronState ? (
              <ul className='pl-3 font-normal flex flex-col gap-3'>
                <li>
                  <Link to={'/privacy-policy'}> Privacy Policy</Link>
                </li>
                <li className='cursor-pointer'>
                  <Link to={'/terms-and-conditions'}> Terms & Conditions</Link>
                </li>
                <li>
                  <Link to={'/restaurant-terms-and-contitions'}>
                    {' '}
                    Restaurant T&C
                  </Link>
                </li>
                <li>
                  <Link to={'/guidelines'}> Guidelines</Link>
                </li>
                <li></li>
              </ul>
            ) : (
              <></>
            )}
          </div>
        </div>
        <div className='md:w-[25%] w-full md:mt-0 mt-4'>
          <div>
            <h1>Get the app</h1>
            <div className='flex items-center gap-5 my-4'>
              <img
                src={GooglePlay}
                width={130}
                alt='google play'
                onClick={openAppleStore}
                className='cursor-pointer'
              />
              <img
                src={AppStore}
                width={130}
                alt='app store'
                onClick={openPlayStore}
                className='cursor-pointer'
              />
            </div>
            {isModalOpen && <NotifyModal onClose={handleCloseModal} />}
          </div>
          <div className='mt-10'>
            <h1>Get in touch </h1>
            <div className='mt-4 flex flex-col  gap-2'>
              <div>
                <Link
                  target='_blank'
                  to={'https://linkedin.com/company/kraverich/'}
                  onClick={() =>
                    trackEvent('button', 'click', 'linkedin_opened')
                  }
                >
                  <div className='flex gap-2 my-1 text-sm items-center'>
                    <FaLinkedin size={20} />
                    linkedin.com/company/kraverich/
                  </div>
                </Link>
              </div>
              <div>
                <Link
                  target='_blank'
                  to={'https://www.instagram.com/kraverich/'}
                  onClick={() =>
                    trackEvent('button', 'click', 'instagram_opened')
                  }
                >
                  <div className='flex gap-2 my-1 text-sm items-center'>
                    <FaInstagram size={20} />
                    instagram.com/kraverich
                  </div>
                </Link>
              </div>
              <div className='flex gap-2 my-1 text-sm items-center '>
                <HiOutlineMail size={20} />
                support@kraverich.com{' '}
              </div>
              <div className='flex gap-2 my-1 text-sm items-center'>
                <LuPhone size={20} />
                +91 96069 74913{' '}
              </div>
            </div>
          </div>
        </div>
        <div className='w-full md:hidden block'>
          <p className='mt-10 text-gray-300 md:text-base text-xs'>
            &copy; 2024 KraveRich Retail Pvt Ltd
          </p>
          <p className='text-gray-300 md:text-base text-xs'>
            All rights reserved.
          </p>
        </div>
      </footer>
    </>
  );
};

export default Footer;
