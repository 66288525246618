import './RestaurantTermsAndConditions.module.scss';

const tableOfContents = [
  { heading: 'OUR SERVICES', id: 'point144' },
  {
    heading: 'INTELLECTUAL PROPERTY RIGHTS',
    id: 'point21',
  },
  {
    heading: 'USER REPRESENTATIONS',
    id: 'point2',
  },
  {
    heading: 'USER REGISTRATION',
    id: 'point3',
  },
  {
    heading: 'PRODUCTS',
    id: 'point4',
  },
  {
    heading: 'PURCHASES AND PAYMENT',
    id: 'point5',
  },
  {
    heading: 'RETURN POLICY',
    id: 'point6',
  },
  {
    heading: 'PROHIBITED ACTIVITIES',
    id: 'point7',
  },
  {
    heading: 'USER GENERATED CONTRIBUTIONS',
    id: 'point8',
  },
  {
    heading: 'CONTRIBUTION LICENCE',
    id: 'point9',
  },
  {
    heading: 'MOBILE APPLICATION LICENCE',
    id: 'point10',
  },
  {
    heading: 'SERVICES MANAGEMENT',
    id: 'point11',
  },
  {
    heading: 'PRIVACY POLICY AND CONTENT GUIDELINES',
    id: 'point12',
  },
  {
    heading: 'TERM AND TERMINATION',
    id: 'point13',
  },
  {
    heading: 'MODIFICATIONS AND INTERRUPTIONS',
    id: 'point14',
  },
  {
    heading: 'GOVERNING LAW',
    id: 'point15',
  },
  {
    heading: 'DISPUTE RESOLUTION',
    id: 'point16',
  },
  {
    heading: 'CORRECTIONS',
    id: 'point17',
  },
  {
    heading: 'DISCLAIMER',
    id: 'point18',
  },
  {
    heading: 'LIMITATIONS OF LIABILITY',
    id: 'point19',
  },
  {
    heading: 'INDEMNIFICATION',
    id: 'point20',
  },
  {
    heading: 'USER DATA',
    id: 'point21',
  },
  {
    heading: 'ELECTRONIC COMMUNICATIONS, TRANSACTIONS, AND SIGNATURES',
    id: 'point22',
  },
  {
    heading: 'MISCELLANEOUS',
    id: 'point23',
  },
  {
    heading: 'CONTACT US',
    id: 'point24',
  },
];

const RestaurantTermsAndConditions = () => {
  return (
    <div className='bg-[#F9F9F9]'>
      <div className='md:pt-1 pt-1 md:mx-48 mx-10 text-[#475467]'>
        <div className='text-center my-10'>
          <h1 className='title md:text-5xl text-[40px] text-purple'>
            Restaurant Terms & Conditions
          </h1>
        </div>
        <section id='point144' className='my-10 flex flex-col gap-5'>
          <h1 className='uppercase text-2xl font-bold'>1. OUR SERVICES</h1>
          <p>
            We are KraveRich Retail Private Limited, doing business as KraveRich
            ('Company', 'we', 'us', or 'our'), a company registered in India at
            #9 Halagevaderahalli, 160 Raja Rajeshwari Nagar, Bengaluru,
            Karnataka 560098. We operate the website https://www.kraverich.com
            (the 'Site', the ‘Website’), the mobile application KraveRich
            Restaurant Partner (the 'App'), as well as any other related
            products and services that refer or link to these legal terms (the
            'Legal Terms', ‘terms’) (collectively, the 'Services'). The Site and
            the App have been jointly referred to as ‘platform’.
            <br />
            <br />
            You (the ‘Restaurant Partner’, the ‘Restaurant’) are seeking to
            engage the Services of KraveRich under a non-exclusive and binding
            agreement, governed by the terms and conditions outlined in this
            contract. Such terms include, but are not limited to, warranties
            provided by both Parties, specified obligations, intellectual
            property rights, confidentiality clauses, clearly defined
            responsibilities, and grounds for termination of this agreement.
            Hereafter, the ‘Restaurant’ and KraveRich shall be individually
            referred to as a 'Party', and collectively as 'Parties', where the
            context so demands.
            <br />
            <br />
            The set of regulations specified herein shall be referred to as the
            "Restaurant Terms and Conditions" (Restaurant T&C). These Restaurant
            T&C, in conjunction with the Terms of Service, shall govern your
            utilization of the KraveRich Platform. You are advised to thoroughly
            peruse the Restaurant T&C prior to engaging with the Platform's
            services. In the event of non-acceptance of these Restaurant T&C,
            you are prohibited from utilizing the Platform's services as a
            restaurant partner and are requested to uninstall any associated
            applications. By installing, downloading, or simply using the
            Platform, you are entering into a contractual agreement with
            KraveRich, confirming your acceptance of the Restaurant T&C, Terms
            of Service, and other KraveRich policies (including, but not limited
            to, the Privacy Policy, which is incorporated by reference herein).
            These arrangements shall take effect immediately upon downloading,
            installing, or utilizing the Platform's services.
            <br />
            <br />
            KraveRich acts as an intermediary for facilitating transactions
            between participating restaurants, including yourself, and
            individuals desiring to make purchases ("Customers"). The Platform
            provides Customers with the option to browse and select items
            ("Orders") from a variety of products offered for sale by different
            local restaurants ("Outlets"). KraveRich monitors and tracks food
            intake of customers and provides diet recommendations (‘Meal
            Recommendation Services’) from third-party Expert ‘Nutritionists’ or
            based on algorithms built using sophisticated nutrition formulae.
            Separately, KraveRich also engages third-party delivery partners,
            who operate independently, to manage the pick- up and delivery
            ("Delivery Services") of products procured by Customers from the
            Outlets to their specified locations in select cities across India.
            The Platform Services provided by KraveRich, and the Delivery
            Services offered by the Delivery Partners are collectively
            denominated as the "Services".
          </p>
        </section>
        <section id='point21' className='my-10 flex flex-col gap-5'>
          <h1 className='uppercase text-2xl font-bold'>MODIFICATIONS</h1>
          <h5 className='text-lg'>Our intellectual property</h5>
          <p>
            The Restaurant T&C, as well as other policies established by
            KraveRich, are subject to amendments. We reserve the right to modify
            or update the Restaurant T&C and other KraveRich policies at our
            discretion, by publishing the revisions on the Platform and
            notifying you via means we deem to be appropriate, such as email,
            through the platform, in-app notifications or push notifications.
            KraveRich may opt to inform you of such modifications via a separate
            email for your convenience. Nevertheless, it is your responsibility,
            at all times, to periodically review the Restaurant T&C and other
            KraveRich policies displayed on the Platform website and stay
            informed of any changes. By continuing to utilize the Services
            following the publication of any modifications on the Platform, you
            acknowledge and accept the updated Restaurant T&C and other
            KraveRich policies.
          </p>
        </section>
        <section id='point2' className='my-10 flex flex-col gap-5'>
          <h1 className='uppercase text-2xl font-bold'>GENERAL CONDITIONS</h1>
          <ul className='ml-10 list-disc'>
            <li>
              The responsibility for proposing and agreeing to all commercial
              and contractual terms pertaining to the products and services
              offered on the Platform rests solely with you and the Customers.
              KraveRich solely acts as an intermediary for communication
              purposes. It is explicitly acknowledged that any sales contract
              concerning the products or services shall be binding exclusively
              between the Restaurant and the Customer. The commercial and
              contractual terms encompass, but are not limited to, price,
              relevant taxes, shipping expenses, payment methods, payment
              conditions, delivery date, duration, and mode, product quality,
              and post-sale services. KraveRich does not exert any control over,
              determine, advise, or participate in any manner with the
              presentation or acceptance of such commercial and contractual
              terms between the Customers and the Restaurants.
            </li>
            <li>
              KraveRich refrains from providing any assurance or guarantee
              regarding the specifics of any Customer, including, but not
              limited to, their lawful ownership, creditworthiness, or identity.
            </li>
            <li>
              KraveRich bears no liability for any non-compliance or violation
              of contracts established between Customers and Restaurants,
              Customers and Delivery Partners, or Restaurants and Delivery
              Partners on the Platform. KraveRich does not and cannot ensure
              that participants in any transaction will fulfil their respective
              contractual obligations. Additionally, KraveRich is not
              responsible for inadequate or non-compliant services, or any harm,
              delays, or losses arising from out-of-stock, unavailable, or
              back-ordered Products.
            </li>
            <li>
              KraveRich manages an online marketplace, acting as a facilitator
              without taking possession of any Products from the Restaurant
              during Customer transactions on the Platform. KraveRich, at any
              point, has no rights, title, or interests associated with the
              Products and bears no responsibilities or liabilities, including
              product liability, concerning the contract between the Customer
              and Restaurant.
            </li>
            <li>
              Should a Customer raise concerns regarding the effectiveness,
              quality, or other matters related to a Product, KraveRich shall
              inform the Restaurant and, if applicable, direct the Customer to
              the Restaurant's consumer call centre. The Restaurant is solely
              responsible for addressing the Customer's complaint and is
              obligated to take necessary action. KraveRich reserves the right
              to disclose pertinent information, including Restaurant details,
              to facilitate a satisfactory resolution of the complaint.
            </li>
            <li>
              You hereby affirm and acknowledge that KraveRich holds no
              liability with respect to interactions involving minors or
              individuals posing under fraudulent identities
            </li>
            <li>
              {' '}
              You confirm and affirm your agreement to be bound by the Terms of
              Service governing the utilization of the Platform, and you
              acknowledge that your usage of the Platform shall be subject to
              the aforementioned Terms of Service.
            </li>
            <li>
              The Parties acknowledge and agree that any arrangements or
              decisions regarding the extension of discounts on products offered
              by the Restaurant on the Platform shall be reached through mutual
              consent and negotiation on a case-by-case basis.
            </li>
          </ul>
        </section>
        <section id='point3' className='my-10 flex flex-col gap-5'>
          <h1 className='uppercase text-2xl font-bold'>
            TERMS AND CONDITIONS OF SERVICE
          </h1>
          <ul className='ml-10 list-disc'>
            <li>
              KraveRich agrees to provide Platform Services in accordance with
              the terms of the agreement, and KraveRich explicitly disclaims any
              liability arising therefrom, to the fullest extent permitted by
              law.
            </li>
            <li>
              KraveRich hereby clarifies that there is no guarantee or assurance
              provided regarding the placement of Orders by any Customer in
              relation to the Restaurant. The Restaurant bears sole
              responsibility for the display of the store's name and images of
              products offered for selection by Customers. The Restaurant is
              obligated to comply with all applicable laws and regulations,
              including but not limited to the Legal Metrology (Packaged
              Commodities) Rules and the Food Safety and Standards (Labelling
              and Display) Regulations, as amended from time to time, for the
              display of pre-packaged products. The Restaurant shall share a
              legible and clear image of such pre-packaged products, which
              KraveRich shall display on the Platform. It is expressly
              understood that KraveRich shall only facilitate the display of
              such content shared by the Restaurant and shall not be responsible
              for verifying the accuracy or authenticity of the same.
            </li>
            <li>
              KraveRich's role is limited to facilitating the pick-up and
              delivery of Orders placed on the Platform through the designated
              Delivery Personnel (‘Delivery Partner’s), at KraveRich's sole
              discretion. KraveRich does not provide Delivery Services to either
              the Restaurant or the Customer, but rather, merely facilitates the
              provision of Delivery Services between the Delivery Partners and
              the Customer. Under no circumstances shall either Party be held
              responsible for any unauthorized acts of the Delivery Partners, as
              the liability in such cases shall solely rest with the Delivery
              Partners.
            </li>
            <li>
              KraveRich retains the right to decide the areas where it will
              provide the aforementioned services and may add or remove areas as
              deemed appropriate to improve the Delivery Services. KraveRich
              shall facilitate Delivery Services within a specific radius or
              area as determined by KraveRich, which may be subject to change at
              its sole discretion, based on the Restaurant's location in the
              territory. If KraveRich adds other areas, it may be subject to
              revised terms. The Restaurant is solely responsible for
              reimbursing and/or absolving the cost of Disputed Orders, which
              include those for which Customers have either refused to pay or
              have requested a refund due to reasons attributable solely to the
              Restaurant. These reasons may include, but are not limited to:
              <ol className='list-decimal ml-10'>
                <li>
                  The Customer's experience falling short of expectations or the
                  Customer's disappointment regarding the quality of
                  goods/merchandise provided by the seller.
                </li>
                <li>
                  Expired goods, which may include (but are not limited to) food
                  items.
                </li>
                <li>
                  Instances where the Customer receives a damaged or incorrect
                  product, including (but not limited to) food products, and is
                  required to return it.
                </li>
                <li>
                  Situations where the Customer receives a lower quality
                  product, which may include (but are not limited to) food
                  items.
                </li>
                <li>
                  Cases where the Customer receives an incorrect quantity or
                  missing items in their order.
                </li>
                <li>Poor packaging.</li>
              </ol>
            </li>
            <li>
              The Restaurant holds the responsibility to examine all information
              on the Platform and ensure that all details and advertisements are
              current and in accordance with applicable laws. The Restaurant is
              required to update all such information and notify KraveRich of
              any changes, as per the Restaurant Terms. KraveRich shall not be
              held accountable or responsible in any way for any modifications
              to the product description provided by the Restaurant.
            </li>
            <li>
              The Restaurant is solely accountable for providing any
              warranties/guarantees for products sold to the Customers. Under no
              circumstances shall KraveRich bear any responsibility for such
              warranties/guarantees.
            </li>
            <li>
              The Restaurant Representations and Warranties include:
              <ol className='ml-10 list-decimal'>
                <li>
                  <strong>Quality and Safety: </strong>Restaurant warrants that
                  all products offered, particularly food items, are of the
                  highest quality and fit for human consumption. Restaurant
                  shall comply with all applicable food safety and hygiene
                  regulations, including [mention specific regulations, e.g.,
                  Food Safety and Standards Act and Schedule 4 of Food Safety
                  and Standard (Licensing and Registration of Food Businesses)
                  Regulations, 2011].
                </li>
                <li>
                  <strong>Ingredients:</strong>The Restaurant agrees to furnish
                  information regarding the ingredients utilized in its
                  offerings. This information encompasses the nature of the
                  ingredients and the amount employed per dish. The restaurant
                  commits to maintaining the accuracy of these particulars for
                  orders made through the KraveRich platform.
                </li>
                <li>
                  <strong>Licenses and Compliance:</strong>
                  Restaurant represents that it holds all necessary licenses and
                  permits required by applicable laws for its business
                  operations, including a valid FSSAI license displayed on the
                  platform. Restaurant shall comply with all applicable laws and
                  regulations pertaining to its products, services, and
                  anti-bribery and anti-corruption practices. The restaurant is
                  obligated to furnish KraveRich with necessary legal
                  information, including but not limited to the FSSAI license,
                  GSTIN number, Trade License and PAN Card, upon written
                  request, within a period of 5 business days. KraveRich shall
                  use the Legal Documentation for the purpose of verifying the
                  Restaurant's compliance with applicable laws and regulations.
                </li>
                <li>
                  <strong>Content Ownership and Accuracy:</strong>
                  Restaurant warrants that it owns or has the right to use all
                  content provided to KraveRich, including menus, and it does
                  not infringe on any third-party rights or applicable laws. All
                  information provided by Restaurant, including menus and
                  descriptions, is true, accurate, and not misleading.
                </li>
                <li>
                  <strong>Legal Capacity and Binding Agreement:</strong>
                  Restaurant represents that it possesses the full legal
                  authority to enter and fulfil its obligations under this
                  agreement. Restaurant acknowledges that using the platform and
                  services constitutes acceptance of the agreement's terms and
                  conditions, forming a valid and binding legal contract.
                </li>
                <li>
                  <strong>Product Liability and Compliance:</strong>
                  Restaurant bears sole responsibility for the quality of its
                  products and ensures compliance with all applicable laws and
                  regulations, including delivery schedules and timelines
                  established for products on the platform.
                </li>
                <li>
                  <strong>Platform Usage and Integrity:</strong>
                  Restaurant agrees to use the platform solely for its intended
                  purpose and shall not engage in any activities that circumvent
                  platform transactions, including using external methods
                  (phone, email, SMS) to connect with Customers.
                </li>
                <li>
                  <strong>Respectful Conduct:</strong>
                  Restaurant shall ensure that its employees and representatives
                  treat delivery partners, KraveRich representatives, and
                  Customers with respect and courtesy. Unprofessional conduct,
                  including rudeness and offensive language, is strictly
                  prohibited. Restaurant agrees to promptly report any
                  behavioural issues to KraveRich.
                  <ol className='list-decimal ml-10'>
                    <li>
                      <strong> Order Confirmation:</strong>
                      The Restaurant shall promptly confirm all Orders within 6
                      minutes of receiving them through the platform interface.
                      KraveRich may send a reminder after 2 minutes and escalate
                      the Order after 6 minutes if confirmation is not received.
                      Failure to confirm after KraveRich's attempt to contact
                      the Restaurant will result in automatic order cancellation
                      and Restaurant responsibility for the platform service
                      fee.
                    </li>
                    <li>
                      <strong> Order Completion:</strong>
                      All items must be securely packed and readily available
                      for pickup by delivery partners (DELIVERY PARTNERs) upon
                      their arrival. DELIVERY PARTNERs will not be expected to
                      wait more than 5 minutes for packaged items.
                    </li>
                    <li>
                      <strong>Order Accuracy:</strong>
                      The Restaurant is solely responsible for ensuring order
                      accuracy and dispatching products strictly as per Customer
                      orders. The Restaurant bears all costs and consequences of
                      supplying incorrect products. DELIVERY PARTNERs are only
                      responsible for delivering items handed over by the
                      Restaurant in sealed packages and are not obligated to
                      verify order correctness. The Restaurant is responsible
                      for verifying packed items match the order details.
                    </li>
                    <li>
                      <strong> Invoicing:</strong>
                      The Restaurant shall issue a valid tax invoice for each
                      fulfilled order, including a copy in the package delivered
                      to the Customer.
                    </li>
                    <li>
                      <strong>Liability and Indemnification:</strong>
                      The Restaurant is solely liable for any delays in order
                      delivery or repeat deliveries due to their actions or
                      omissions. The Restaurant agrees to fully indemnify
                      KraveRich against any claims arising from delays caused by
                      them.
                    </li>
                    <li>
                      <strong> Branding and Materials:</strong>
                      You agree to use all marketing materials and collateral
                      provided by KraveRich for orders fulfilled through their
                      platform, as instructed by KraveRich.
                    </li>
                    <li>
                      <strong> No Third-Party Branding:</strong>
                      You are prohibited from displaying labels, trademarks,
                      branding, or materials from any third parties, especially
                      KraveRich's direct competitors, on deliveries made through
                      their platform.
                    </li>
                    <li>
                      <strong> Product Images:</strong>
                      You are required to share clear and high-quality images of
                      your products offered on the platform, adhering to
                      KraveRich's image guidelines. Photography services can be
                      provided on request at an additional cost.
                    </li>
                    <li>
                      <strong>Competitive Pricing:</strong>
                      You guarantee to offer competitive pricing for your
                      products on KraveRich compared to your other sales
                      channels, including online aggregators and physical
                      stores. This applies to product features, quality,
                      quantity, and packaging. KraveRich will notify you of any
                      complaints regarding price discrepancies with other
                      channels, and you are solely responsible for resolving
                      them within 3 business days and reporting the resolution
                      to KraveRich.
                    </li>
                    <li>
                      <strong>MRP Adherence:</strong>
                      Pre-packaged products must be listed at prices equal to or
                      below the MRP printed on the product. Violators can be
                      suspended and potentially blacklisted.
                    </li>
                    <li>
                      <strong>Consequences of Non-Compliance:</strong>
                      If you violate clause ‘m’ and a Customer complaint,
                      KraveRich may verify the claim and issue a refund to the
                      Customer. The refunded amount will be deducted from your
                      future settlements.
                    </li>
                    <li>
                      <strong> Ancillary Charges:</strong>
                      You agree to be aware of and promptly adhere to any
                      advisories issued by KraveRich regarding ancillary charges
                      allowed for Customers on the platform. These advisories
                      are designed to reflect best practices and benefit both
                      Restaurants and Customers.
                    </li>
                  </ol>
                </li>
              </ol>
            </li>
            <li>
              Brand Usage for Promotion: a. Mutual Grant: <br />
              a. Both KraveRich and the Restaurant (hereinafter referred to as
              "each Party") grant each other a non-exclusive, non-transferable,
              worldwide, royalty-free license to use, copy, publish, and display
              the other Party's trademarks, logos, name, and related information
              (collectively, "Brand Assets").
              <br />
              <br />
              b. Purpose Restriction: This license is limited to promoting and
              marketing the collaboration between KraveRich and the Restaurant
              and related purposes, specifically for publishing on the KraveRich
              platform.
              <br />
              <br />
              c. KraveRich Brand Promotion: KraveRich reserves the right, at its
              sole discretion, to further promote the brand within the
              Restaurant's outlet(s), subject to mutually agreed-upon
              compensation terms.
            </li>
            <li>
              Termination for Breach or Non-Compliance: KraveRich reserves the
              right to temporarily suspend, permanently terminate, or
              discontinue services with immediate effect, despite these terms
              and conditions, if the Restaurant commits a material breach or
              non-compliance. This includes, but is not limited to, the
              following:
              <ol className='ml-10 list-disc'>
                <li>
                  <strong>Poor Product Quality:</strong>
                  1. Consistent negative Customer feedback (poor ratings)
                  reported directly or indirectly to KraveRich concerning
                  product quality. 2. Complaints received through calls or other
                  mediums regarding product quality deficiencies.
                </li>
                <li>
                  Violation of any provision of:
                  <ol className='list-decimal'>
                    <li>Information Technology Act, 2000 and its rules.</li>
                    <li>
                      Food Safety and Standards Act, 2006 and its rules and
                      regulations.
                    </li>
                    <li>Legal Metrology Act, 2009 and its rules.</li>
                    <li>Drugs and Cosmetics Act, 1940 and its Rules.</li>
                    <li>
                      Drugs and Magic Remedies (Objectionable Advertisements)
                      Act, 1954.
                    </li>
                    <li>
                      Any other applicable domestic laws, rules, and regulations
                      (including exchange control regulations).
                    </li>
                    <li>
                      International laws, foreign exchange laws, statutes,
                      ordinances, and regulations (including taxes, customs, and
                      local levies).
                    </li>
                    <li>
                      <strong>Delivery Delays:</strong>
                      Consistent failure to adhere to KraveRich's estimated
                      delivery schedules and timelines for products listed on
                      the platform.
                    </li>
                    <li>
                      <strong>Misconduct:</strong>
                      Misbehaviour towards KraveRich's representatives, Delivery
                      Partners (DELIVERY PARTNERs), or customers.
                    </li>
                    <li>
                      <strong> Competitor Promotion:</strong>
                      Inclusion of competitor marketing or promotional materials
                      in orders delivered by DELIVERY PARTNERs.
                    </li>
                    <li>
                      <strong> Warranty Breaches:</strong>
                      Violation of any representation or warranty made by the
                      Restaurant.
                    </li>
                    <li>
                      <strong>Other Material Breaches:</strong>
                      Any other significant violation of these terms and
                      conditions.
                    </li>
                  </ol>
                </li>
              </ol>
            </li>
            <li>
              KraveRich will solely determine whether to resume providing
              services to the Restaurant after a suspension or termination.
            </li>
            <li>
              <strong>Delisting for Non-Compliance:</strong>
              Notwithstanding anything in clause (ix) of Terms and Conditions of
              Service, KraveRich retains the right to immediately remove any
              product from the platform if it violates applicable laws.
            </li>
            <li>
              <strong> Non-Exclusivity:</strong>
              The Restaurant acknowledges that all services offered by KraveRich
              on its platform are non- exclusive.
            </li>
            <li>
              <strong> Termination:</strong>
              Either party can terminate this agreement with 45 days' written
              notice to the other. KraveRich may delist the Restaurant after the
              45th day from the notice date. Upon termination, the Restaurant
              must fulfil any orders placed before termination, and KraveRich
              will receive its service fee for those orders. KraveRich may opt
              to suspend services during the notice period.
            </li>
            <li>
              <strong>Communication:</strong>
              KraveRich may manage an email address on your behalf on the
              KraveRich.com domain for customer communication and
              correspondence.
            </li>
            <li>
              <strong> Platform Modifications:</strong>
              KraveRich reserves the right to add, remove, or modify platform
              categories and their associated conditions at its discretion.
            </li>
          </ul>
        </section>
        <section id='point4' className='my-10 flex flex-col gap-5'>
          <h1 className='uppercase text-2xl font-bold'>
            ACCOUNT SECURITY AND INFORMATION ACCURACY
          </h1>
          <ul className='ml-10 list-disc'>
            <li>
              You are solely responsible for keeping your login ID and password
              confidential and liable for any activities occurring under your
              credentials.
            </li>
            <li>
              You agree to provide truthful, accurate, and up-to-date
              information when required under these terms. If we have reason to
              believe the information you provide is false, misleading, or
              violates these terms, we may indefinitely suspend, terminate, or
              block your access to the platform.
            </li>
            <li>
              Your Restaurant registration with KraveRich is strictly for your
              individual use and cannot be transferred to another party.
            </li>
            <li>
              You are required to promptly notify KraveRich of any suspected
              unauthorized access or activities within one business day of their
              occurrence for immediate action.
            </li>
          </ul>
        </section>
        <section id='point5' className='my-10 flex flex-col gap-5'>
          <h1 className='uppercase text-2xl font-bold'>
            COMMUNICATION CONSENT
          </h1>
          We accept the following forms of payment:
          <p>
            By using the Restaurant Interface/Platform or sending us emails,
            data, information, or other communication, you agree to receive
            notifications and information from us electronically. This includes
            periodic updates and communications deemed necessary by us. We may
            contact you via email or other electronic or non-electronic methods.
          </p>
        </section>
        <section id='point6' className='my-10 flex flex-col gap-5'>
          <h1 className='uppercase text-2xl font-bold'>
            APP ACCESS AND RESPONSIBILITIES
          </h1>
          <ul className='ml-10 list-decimal'>
            <li>
              <strong>Device and App Requirements:</strong>
              <br />
              a. You will be granted access to the KraveRich Restaurant Partner
              Application on a handheld device (tablet or mobile phone). b. You
              are responsible for ensuring your device is functional and has the
              latest version of the app installed at all times. c. This device
              will be used for: 1. Receiving and confirming customer orders. 2.
              Providing estimated delivery times. 3. Rejecting orders (if
              necessary).
            </li>
            <li>
              <strong> Order Acceptance and Communication:</strong>
              <br /> a. You cannot bypass the platform and accept orders
              directly from customers via phone or email. b. If you decline an
              order through the app, you may not attempt to contact the customer
              directly to fulfil the order outside the platform. c. Your
              communications with KraveRich may be recorded for feedback and
              improvement.
            </li>
            <li>
              <strong>Inventory and Operation Management:</strong>
              <br />
              a. You are responsible for maintaining real-time updates on your
              inventory levels and outlet operation times within the app. This
              ensures accurate order acceptance and customer experience.
            </li>
          </ul>
        </section>
        <section id='point7' className='my-10 flex flex-col gap-5'>
          <h1 className='uppercase text-2xl font-bold'>DISCLAIMERS</h1>
          <p>
            To the maximum extent permitted by applicable law, KraveRich and its
            affiliates, along with each of their officers, directors, members,
            employees, and agents, disclaim all warranties, whether express or
            implied, related to this Agreement, the Platform, and the KraveRich
            services, as well as any use thereof. Such disclaimed warranties
            include, but are not limited to, implied warranties of Edibility,
            fitness for a particular purpose, and non-infringement. KraveRich
            does not provide warranties or representations
            <br />
            <br />
            regarding the accuracy or completeness of the content and data on
            the Platform or within the KraveRich services, nor does it warrant
            the content of any linked websites. KraveRich assumes no liability
            or responsibility for:
            <br />
            <br />
            (a) errors, inaccuracies, or mistakes in content and materials.
            <br />
            (b) personal injury or property damage resulting from Restaurant
            Partners' use of the Platform and KraveRich services.
            <br />
            (c) unauthorized access to KraveRich's servers or personal and
            financial information stored therein.
            <br />
            (d) interruptions or cessation of transmission to or from the
            website or KraveRich services.
            <br />
            (e) bugs, viruses, trojan horses, or similar issues transmitted
            through the website or KraveRich services by third parties; and/or
            (f) errors or omissions in any content and materials or any loss or
            damage incurred from the use of posted or transmitted content via
            the website or KraveRich services.
          </p>
        </section>
        <section id='point8' className='my-10 flex flex-col gap-5'>
          <h1 className='uppercase text-2xl font-bold'>
            CONFIDENTIALITY OF INFORMATION
          </h1>
          <p>
            Both parties acknowledge that during this agreement, they may
            disclose confidential information to each other that is valuable and
            not publicly known. This information may include, but is not limited
            to:
          </p>
          <ul className='ml-10 list-disc'>
            <li>
              Unpublished software code, technical processes, and product
              designs.
            </li>
            <li>Financial information and business plans.</li>
            <li>Advertising and marketing materials.</li>
            <li>
              Your Contributions are not false, inaccurate, or misleading.
            </li>
            <li>Ingredient Details provided by the Restaurant to KraveRich.</li>
          </ul>
          <p>
            Both parties agree to maintain the confidentiality of all such
            information, treating it with the same care they would afford their
            own confidential information. This obligation extends throughout the
            term of the agreement and for a period of [2 years] following its
            termination.
          </p>
        </section>
        <section id='point9' className='my-10 flex flex-col gap-5'>
          <h1 className='uppercase text-2xl font-bold'>
            INTELLECTUAL PROPERTY
          </h1>
          <ul className='ml-10 list-disc'>
            <li>
              Each party retains exclusive ownership of their respective trade
              names, service marks, inventions, copyrights, trade secrets,
              patents, and other intellectual property ("Intellectual
              Property"). These Restaurant Terms do not grant a license or right
              to use any party's Intellectual Property except as explicitly
              outlined in these Restaurant Terms. Both you and we agree that if
              any third-party claims infringement of their intellectual property
              rights on the Platform, and if such contested content shared on
              the Platform is attributed to you, it is your responsibility to
              investigate, defend, settle, and resolve any such claims related
              to intellectual property infringement concerning that content on
              the Platform.
            </li>
            <li>
              You acknowledge that KraveRich holds registered ownership of the
              word marks "KraveRich" as well as any registered logos. This
              includes but is not limited to its variations ("Licensed Marks").
              Furthermore, you agree not to directly or indirectly challenge or
              aid others in challenging the validity of KraveRich's or its
              affiliates' proprietary rights in the Licensed Marks, their
              registrations, or to file any applications for registration of the
              Licensed Marks or any names or logos derived from or confusingly
              similar to the Licensed Marks, in any product or service, and in
              any territory worldwide. If you identify any infringements of the
              Licensed Marks, please notify us at
              partner-relations@kraverich.com.
            </li>
            <li>
              KraveRich acknowledges and respects the intellectual property
              rights of third parties and has established an "IP Notice and Take
              Down Policy" to facilitate the reporting of listings that violate
              these rights, ensuring the prompt removal of infringing products
              from the site. Such violations of intellectual property undermine
              trust between Customers and reputable Restaurants.
            </li>
            <li>
              {' '}
              Only the owner of intellectual property rights can report
              potentially infringing products or listings through the "Take Down
              Policy" by submitting a Notice of infringement in the specified
              format. If you are not the owner of the intellectual property
              rights, you can still assist by contacting the rights owner and
              urging them to reach out to us.
            </li>
          </ul>
          <p>
            <strong> Restaurant Responsibility:</strong> Please be aware that
            KraveRich is not responsible for verifying whether Restaurants have
            the legal right or ability to sell or distribute their listed
            products. You, as the Restaurant, are solely responsible for
            ensuring your listings comply with all applicable laws and
            regulations, including intellectual property rights. Diligently
            report any instances of infringement upon KraveRich's intellectual
            property that come to your attention.
            <br />
            <br />
            <strong>KraveRich's Commitment:</strong> However, KraveRich is
            committed to maintaining a platform free from intellectual property
            infringement. If an authorized representative of a rights owner
            provides a proper report of infringement concerning a listed item,
            KraveRich will take prompt action to investigate and, if necessary,
            remove the infringing listing.
          </p>
        </section>
        <section id='point10' className='my-10 flex flex-col gap-5'>
          <h1 className='uppercase text-2xl font-bold'>INDEMNIFICATION</h1>
          <h5>Use Licence</h5>
          <p>
            You agree to defend, indemnify, and hold harmless KraveRich, its
            affiliates, and their respective officers, directors, employees, and
            agents from any actions, third-party lawsuits, claims, liabilities,
            losses, damages, and expenses (including reasonable attorney's fees)
            arising out of or related to:
            <br />
            a. Orders, services, or offers you provide. b. Content you list on
            the Platform.
          </p>
        </section>
        <section id='point11' className='my-10 flex flex-col gap-5'>
          <h1 className='uppercase text-2xl font-bold'>
            LIMITATION OF LIABILITY
          </h1>
          <p>
            FOR THE PURPOSES OF THIS CLAUSE, "LIABILITY" REFERS TO LIABILITY
            ARISING FROM BREACH OF CONTRACT, NEGLIGENCE, MISREPRESENTATION,
            TORTIOUS CLAIMS, RESTITUTION, OR ANY OTHER CAUSE OF ACTION RELATED
            TO OR ARISING UNDER THIS AGREEMENT. THIS INCLUDES LIABILITY
            EXPRESSLY PROVIDED FOR IN THIS AGREEMENT OR ARISING DUE TO THE
            INVALIDITY OR UNENFORCEABILITY OF ANY TERM OF THIS AGREEMENT.
            KRAVERICH DOES NOT EXCLUDE OR LIMIT LIABILITY FOR ANY LIABILITY THAT
            CANNOT BE EXCLUDED BY LAW. SUBJECT TO THE PRECEDING SENTENCE,
            NEITHER PARTY SHALL BE LIABLE FOR LOSS OF ACTUAL OR ANTICIPATED
            PROFITS, LOSS OF GOODWILL, LOSS OF BUSINESS, LOSS OF REVENUE OR USE
            OF MONEY, LOSS OF CONTRACTS, LOSS OF ANTICIPATED SAVINGS, LOSS OF
            DATA AND/OR UNDERTAKING THE RESTORATION OF DATA, FRAUDULENT ORDERS,
            ANY SPECIAL, INDIRECT, OR CONSEQUENTIAL LOSS. SUCH LIABILITY IS
            EXCLUDED WHETHER IT IS FORESEEABLE, KNOWN, FORESEEN, OR OTHERWISE.
            WHILE KRAVERICH WILL MAKE ITS BEST EFFORTS TO PREVENT UNINTENTIONAL
            OPERATIONAL ERRORS, IT CANNOT PROVIDE ANY WARRANTY OR GUARANTEE IN
            THIS REGARD. DESPITE ANY CONTRARY PROVISIONS HEREIN, KRAVERICH'S
            TOTAL LIABILITY UNDER THIS RESTAURANT T&C SHALL NOT EXCEED THE TOTAL
            VALUE OF A DISPUTED ORDER. REGARDLESS OF ANYTHING CONTAINED HEREIN,
            THE RESPONSIBILITY AND LIABILITY FOR THE AFOREMENTIONED CLAIMS OR
            DECLARATIONS SHALL SOLELY REST WITH THE RESTAURANTS. KRAVERICH SHALL
            NOT BE LIABLE OR RESPONSIBLE FOR ANY CLAIMS OR DECLARATIONS MADE BY
            THE RESTAURANT ON THE PLATFORM AND SHALL BE DULY INDEMNIFIED WHERE
            ANY CLAIMS ARISE FROM SUCH DECLARATIONS.
          </p>
        </section>
        <section id='point12' className='my-10 flex flex-col gap-5'>
          <h1 className='uppercase text-2xl font-bold'>
            13. PRIVACY POLICY and CONTENT GUIDELINES
          </h1>
          <p>
            We care about data privacy and security. Please review our Privacy
            Policy: https://www.privacy.kraverich.com. By using the Services,
            you agree to be bound by our Privacy Policy, which is incorporated
            into these Legal Terms. Please be advised the Services are hosted in
            India. If you access the Services from any other region of the world
            with laws or other requirements governing personal data collection,
            use, or disclosure that differ from applicable laws in India, then
            through your continued use of the Services, you are transferring
            your data to India, and you expressly consent to have your data
            transferred to and processed in India.
            <br />
            <br />
            Your continued use constitutes your affirmation that you have read,
            grasped, and consented to the stipulated Guidelines and Policies
            pertaining to Content.
          </p>
        </section>{' '}
        <section id='point13' className='my-10 flex flex-col gap-5'>
          <h1 className='uppercase text-2xl font-bold'>NO WAIVER</h1>
          <p>
            The failure of either party to assert any of its rights under the
            Form, including, but not limited to, the right to terminate the Form
            in the event of breach or default by the other party, will not be
            deemed to constitute a waiver by that party of its right thereafter
            to enforce each and every provision of the Form in accordance with
            these Terms. Any rights arising from this agreement can be enforced
            at any time without prejudice to previous instances.
          </p>
        </section>{' '}
        <section id='point14' className='my-10 flex flex-col gap-5'>
          <h1 className='uppercase text-2xl font-bold'>RELATIONSHIP</h1>
          <p>
            The parties acknowledge that under these Restaurant Terms and
            Conditions, no inference should be drawn to suggest the existence of
            an employer-employee, master-servant, principal-agent, partnership,
            or any form of joint venture relationship between them or between
            them and their respective contractors/employees. Each party operates
            independently in fulfilling their obligations hereunder and is not
            empowered to undertake or establish any obligations, whether
            explicit or implicit, on behalf of the Restaurant or the other
            party.
          </p>
        </section>{' '}
        <section id='point15' className='my-10 flex flex-col gap-5'>
          <h1 className='uppercase text-2xl font-bold'>16. GOVERNING LAW</h1>
          <p>
            These Legal Terms shall be governed by and defined following the
            laws of India. KraveRich Retail Private Limited and yourself
            irrevocably consent that the courts of India shall have exclusive
            jurisdiction to resolve any dispute which may arise in connection
            with these Legal Terms.
          </p>
        </section>{' '}
        <section id='point16' className='my-10 flex flex-col gap-5'>
          <h1 className='uppercase text-2xl font-bold'>PAYMENT TERMS</h1>
          <ol className='ml-10 list-decimal flex flex-col gap-5'>
            <li>
              the term "Gross Value" refers to the total sum of the following
              components stored in KraveRich’s database: (a) the items
              comprising each Order, calculated based on the price, rates, and
              features of items listed in the menu; (b) any packaging charges or
              other fees payable by the Customer to the Restaurant, if
              applicable; and (c) GST and any other applicable taxes.
            </li>
            <li>
              The term "Service Fee" encompasses: (a) "Platform Service Fee,"
              which denotes the fee for Platform Services provided by KraveRich
              to the Restaurant and includes listing fees and lead generation
              fees; and (b) "Delivery Service Fee," which signifies the fee for
              Delivery Services facilitated by KraveRich through Delivery
              Partners.{' '}
            </li>
            <li>
              The term "Successfully Delivered Order" refers to Orders for which
              payments have been collected.{' '}
            </li>
            <li>
              The term "Disputed Order/s" refers to Orders where Customers have
              either declined payment or requested a partial or full refund due
              to reasons attributable to the Restaurant. Such reasons may
              include, but are not limited to, unmet expectations,
              dissatisfaction with product quality, undelivered Orders,
              discrepancies between the delivered product and the placed Order,
              or instances where the Customer requests a replacement. In such
              cases, KraveRich is entitled to recover the Service Fee from the
              Restaurant's collections. It is clarified that KraveRich bears no
              responsibility to compensate for Disputed Orders. KraveRich
              reserves the right to recover from the Restaurant the amount
              refunded to Customers up to the Order value. Additionally, if a
              Customer requests a replacement, the Restaurant must provide it
              free of charge, and KraveRich will not levy a Service Fee for the
              replacement Order. KraveRich commits to implementing robust
              controls to prevent Customer misuse to the best of its ability.
            </li>
            <li>The term “GST” shall mean goods and service tax.</li>
            <li>
              The term "Other Services" pertains to additional services
              occasionally provided by KraveRich to Restaurants.
            </li>
            <li>
              The term "Other Charges" refers to fees that may be imposed on the
              Restaurant for additional services periodically provided.{' '}
            </li>
            <li>
              The term "Refund for Disputed Order/s" denotes the reimbursement
              provided to the Customer when they claim either partial or full
              refund, as applicable, for reasons attributable to the Restaurant.
              These reasons may include, but are not limited to, unmet Customer
              expectations or dissatisfaction, issues related to product quality
              (such as expired or damaged goods, receiving the wrong item),
              inferior quality products, incorrect quantity or missing items in
              the order, and inadequate packaging.
            </li>
            <ul>
              <li>
                "Restaurant Protection for Cancellation" refers to compensation
                disbursed to the Restaurant in the event of Order cancellations,
                whether initiated via the Platform or through customer care, by
                either the Customer or KraveRich, under the following
                conditions: (a) when the cancellation is not attributable to the
                Restaurant, or (b) when the cancellation is neither attributable
                to nor within the control of KraveRich, provided that the Order
                has been accepted and confirmed for preparation by the
                Restaurant, excluding cancellations initiated by the restaurant.
                The calculation and disbursement process are outlined as
                follows:
              </li>
              <br />
              (i) If a Customer cancels an Order before pickup, the Restaurant
              shall not receive any payment for such Orders. <br />
              (ii) If a Customer cancels an Order after pickup, the Restaurant
              shall receive full compensation equivalent to 100% of the Gross
              Value, subject to adjustments for Service Fee, Other Charges, and
              any deductibles arising from applicable schemes.
            </ul>
            <li>
              KraveRich will levy Service Fees on Restaurants for the services
              rendered. Restaurants are obligated to remit these Service Fees to
              KraveRich as per the terms agreed upon in the duly executed
              memorandum of understanding (MoU) between KraveRich and the
              Restaurants. This agreement encompasses any amendments made
              thereto. The Service Fees are subject to GST and other applicable
              taxes at prevailing rates.
            </li>
            <li>
              {' '}
              KraveRich will collect payments for undisputed Orders from
              Customers, known as "Collections," and transfer them to the
              specified bank account of the Restaurant. KraveRich reserves the
              right to deduct the following items, referred to as "Deductions,"
              from the Collections: (i) Service Fees for the relevant Settlement
              Period, including fees for Disputed Orders; (ii) any applicable
              Other Charges; (iii) GST and other taxes at prevailing rates,
              deducted from the weekly settlement remittance for the
              corresponding Settlement Period; (iv) Restaurant Protection for
              Cancellation Charges; (v) refunds for disputed Orders, if any; and
              (vi) any amounts owed to KraveRich under any other agreements or
              arrangements between KraveRich and the Restaurant.
            </li>
            <li>
              Upon the expiration or earlier termination of the MoU as per its
              terms, KraveRich and the Restaurant shall solely be obligated to
              fulfil Orders placed through the Platform before said expiration
              or termination. KraveRich retains the right to deduct payments for
              these Orders in accordance with the Payment Terms outlined herein.
            </li>
            <li>
              If there are any changes to the product Value, the Restaurant must
              notify KraveRich at least four working days before implementing
              such changes. The System Value will be updated based on the
              changes communicated by the Restaurant, effective from the fifth
              working day after KraveRich receives the written communication. It
              is important to note that for determining the value of an Order,
              only the product Value as updated in compliance with this clause
              will be considered final. KraveRich shall not assume any liability
              or responsibility for changes in menus by the Restaurant unless
              previously informed by the Restaurant to KraveRich in accordance
              with this clause.
            </li>
            <li>
              <strong>Mode of Payment:</strong>
              <ol className='ml-10 list-disc'>
                <li>
                  The settlement of Collections will take place weekly,
                  following the payment systems and nodal account guidelines
                  mandated by the Reserve Bank of India. This weekly settlement,
                  referred to as the "Settlement Period," permits adjustments
                  for Disputed Orders, as detailed above. For Orders fulfilled
                  from Sunday to Saturday, the Settlement Period concludes on or
                  before Wednesday of the subsequent week. Should the Settlement
                  Period coincide with a bank holiday, it will be postponed to
                  the following business day.
                </li>
                <li>
                  KraveRich will generate invoices for the Service Fees for the
                  relevant Settlement Period, and these fees will be subtracted
                  from the Collections. The remaining balance of Collections
                  will be disbursed by KraveRich to the Restaurant at the
                  conclusion of the Settlement Period. vii. Tax Collected at
                  Source (‘TCS’) under Central Goods and Services Tax Act, 2017:
                  TCS shall be collected at the rate of 1% of the Gross Value on
                  the sale of all goods.
                </li>
              </ol>
            </li>
          </ol>
        </section>{' '}
        <section id='point17' className='my-10 flex flex-col gap-5'>
          <h1 className='uppercase text-2xl font-bold'>
            GOVERNING LAW AND DISPUTE RESOLUTION
          </h1>
          <p>
            This Agreement's terms and conditions pertaining to the Restaurant
            shall be governed by and interpreted in accordance with the laws of
            India. Should any dispute arise concerning the Services that the
            Parties are unable to resolve within a period of 30 days, it shall
            be referred to arbitration by a single arbitrator agreed upon by
            both Parties. The arbitration proceedings shall adhere to the
            Arbitration and Conciliation Act of 1996, or any subsequent legal
            enactment or amendment currently in effect. Arbitration sessions
            shall take place in Bangalore, with the proceedings conducted in the
            English language. Subject to the foregoing, the courts in Bangalore
            shall retain exclusive jurisdiction.
          </p>
        </section>{' '}
        <section id='point18' className='my-10 flex flex-col gap-5'>
          <h1 className='uppercase text-2xl font-bold'>SEVERABILITY</h1>
          <p>
            Invalidity or unenforceability of any provision of or right arising
            pursuant to these Terms shall not adversely affect the validity or
            enforceability of the remaining obligations or provisions. Should a
            court of competent jurisdiction deem any provision within these
            Restaurant Terms and Conditions invalid or unenforceable, the
            remaining provisions that can be upheld without reliance on the
            invalidated provision shall remain fully effective, and their
            validity shall remain intact without impairment or nullification
          </p>
        </section>{' '}
        <section id='point19' className='my-10 flex flex-col gap-5'>
          <h1 className='uppercase text-2xl font-bold'>NOTICE</h1>
          <p>
            All notices regarding these Restaurant Terms and Conditions must be
            sent via registered post with acknowledgment due, contemporary
            courier, or email to the address provided below: Lift Off, CIIE-IIMA
            IIM Ahmedabad, Vastrapur, Ahmedabad Ahmedabad, Gujarat 380015 India
            Communication hours are from 9:30 am to 5:30 pm, Monday to Friday.
            If the Restaurant identifies any discrepancies in the Settlement of
            Collections, they may initiate a resolution process by submitting a
            ticket via email to partner-relations@kraverich.com. Both parties
            agree to address and resolve such issues within 30 days from the
            ticket's creation date. KraveRich will provide notice to the
            Restaurant via registered post, contemporary courier, partner App,
            email, or the mobile number registered with KraveRich.
          </p>
        </section>{' '}
        <section id='point20' className='my-10 flex flex-col gap-5'>
          <h1 className='uppercase text-2xl font-bold'>FORCE MAJEURE</h1>
          <p>
            IN THE EVENT THAT, DURING THE TERM OF THE AGREEMENT BETWEEN THE
            RESTAURANT AND KRAVERICH, EITHER PARTY IS UNABLE TO FULFILL THEIR
            OBLIGATIONS UNDER THE CONTRACT DUE TO CIRCUMSTANCES BEYOND THEIR
            CONTROL, INCLUDING BUT NOT LIMITED TO ACTS OF GOD, WAR, HOSTILITIES,
            INVASIONS, ACTS OF PUBLIC ENEMIES, CIVIL COMMOTION, SABOTAGE, FIRE,
            EXPLOSIONS, FLOODS, CYCLONES, EARTHQUAKES, EPIDEMICS, GOVERNMENT
            RESTRICTIONS ON BUSINESS OPERATIONS, ADVERSE CLIMATIC CONDITIONS, OR
            OTHER UNFORESEEN EVENTS OR CIRCUMSTANCES (HEREINAFTER REFERRED TO AS
            "FORCE MAJEURE EVENTS"), DIRECTLY IMPACTING THE PERFORMANCE OF
            OBLIGATIONS AND BEYOND THE REASONABLE CONTROL OF EITHER PARTY, THE
            AFFECTED PARTY SHALL PROMPTLY NOTIFY THE OTHER PARTY WITHIN 21 DAYS
            OF THE OCCURRENCE OF SUCH FORCE MAJEURE EVENTS. IN SUCH CASES, THE
            OBLIGATIONS OF THE NOTIFYING PARTY SHALL BE SUSPENDED WITHOUT ANY
            LIABILITY TOWARDS THE OTHER PARTY FOR THE DURATION OF THE FORCE
            MAJEURE EVENTS, EXCEPT FOR ANY OBLIGATIONS THAT MAY HAVE ALREADY
            ACCRUED UP TO THE COMMENCEMENT DATE OF THE FORCE MAJEURE EVENTS.
          </p>
        </section>{' '}
      </div>
    </div>
  );
};

export default RestaurantTermsAndConditions;
