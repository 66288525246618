import { useState } from 'react';
import { FaBars } from 'react-icons/fa6';
import { MdClose } from 'react-icons/md';
import { Link } from 'react-router-dom';

import { trackEvent } from 'utils/trackevent';
import logo from '../../images/logo.svg';

const Navigation = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  return (
    <div
      className={`relative flex items-center justify-between ${isMenuOpen ? '' : ''} bg-white px-2 md:px-[160px] py-3 w-full h-18 gap-6 sticky top-0`}
    >
      <div className='flex flex-col ml-4 md:ml-0'>
        <Link
          to='/'
          onClick={() => trackEvent('button', 'click', 'home_screen_click')}
        >
          <img src={logo} alt='Logo' className='h-10 md:h-13' />
        </Link>
      </div>

      <div
        onClick={() => setIsMenuOpen(!isMenuOpen)}
        className={`md:hidden block pr-5`}
      >
        {isMenuOpen ? <MdClose size={25} /> : <FaBars size={25} />}
      </div>
      <div
        className={`${isMenuOpen ? 'flex shadow-md' : 'hidden'} md:shadow-none md:block md:static absolute top-[100%] md:w-auto w-full left-0 md:flex md:flex-row flex-col bg-white md:items-center items-start text-gray-500  gap-6 md:gap-8 text-md md:text-base md:p-0 p-4`}
      >
        <div>
          <Link
            onClick={() => {
              setIsMenuOpen(false);
              trackEvent('button', 'click', 'about_screen_click');
            }}
            to='/about'
          >
            about
          </Link>
        </div>
        <div>
          <Link
            onClick={() => {
              setIsMenuOpen(false);
              trackEvent('button', 'click', 'restaurant_partner_clicked');
            }}
            to='/partner'
            className='underline'
          >
            restaurant partner
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Navigation;
