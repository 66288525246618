import { Suspense, useEffect } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import ErrorBoundary from './components/ErrorBoundary';
import LandingPage from './pages/LandingPage';

import Loading from 'components/Loading';
import NotFound from 'components/NotFound';
import About from 'pages/About';
import ContactUs from 'pages/ContactUs';
import Footer from 'pages/Footer';
import Guidelines from 'pages/Guidelines';
import Navigation from 'pages/Header';
import PartnerWithUs from 'pages/PartnerWithUs';
import PrivacyPolicy from 'pages/PrivacyPolicy';
import RestaurantTermsAndConditions from 'pages/RestaurantTermsAndConditions';
import TermsAndConditions from 'pages/TermsAndConditions';

const Dashboard = () => {
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
    window.gtag('config', 'G-8QVYRGFYYB', {
      page_path: location.pathname,
    });
  }, [location]);
  return (
    <>
      <ErrorBoundary>
        <div>
          <Navigation />
          <div>
            <Suspense fallback={<Loading />}>
              <Routes>
                <Route path='/' element={<LandingPage />} />
                <Route path='/about' element={<About />} />
                <Route path='/contact-us' element={<ContactUs />} />
                <Route path='/partner' element={<PartnerWithUs />} />
                <Route path='/privacy-policy' element={<PrivacyPolicy />} />
                <Route
                  path='/terms-and-conditions'
                  element={<TermsAndConditions />}
                />
                <Route
                  path='/restaurant-terms-and-contitions'
                  element={<RestaurantTermsAndConditions />}
                />
                <Route path='/guidelines' element={<Guidelines />} />
                <Route path='*' element={<NotFound />} />
              </Routes>
            </Suspense>
          </div>
          <Footer />
        </div>
      </ErrorBoundary>
    </>
  );
};

export default Dashboard;
