import { useState } from 'react';
import coming_soon from '../../../images/landing/coming_soon.png';

function NotifyModal({ onClose }) {
  const [email, setEmail] = useState('');

  const handleNotifyMe = e => {
    e.preventDefault();
    console.log('Email submitted:', email);
  };

  return (
    <div className='fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50'>
      <div className='bg-white p-6 rounded-lg w-full max-w-md mx-4'>
        <div className='text-center mb-4'>
          <img
            src={coming_soon}
            alt='Logo'
            className='w-full max-w-sm mx-auto rounded-lg'
          />
        </div>
        <div className='text-left mb-4'>
          <p className='text-gray-700 mx-2 '>
            Be first to know when Kraverich is available.
          </p>
        </div>
        <form onSubmit={handleNotifyMe} className='space-y-4'>
          <div>
            <label htmlFor='email' className='text-sm font-semibold mx-2'>
              Add work or institute email ID
            </label>
            <input
              type='email'
              id='email'
              className='w-full md:w-96 sm:w-96 p-2 border border-gray-300 rounded-lg mx-2'
              placeholder='Enter Email ID'
              value={email}
              onChange={e => setEmail(e.target.value)}
              required
            />
          </div>
          <div className='flex flex-col md:flex-row md:justify-end justify-center md:space-x-4 space-y-2 md:space-y-0 px-2'>
            <button
              type='submit'
              className='w-full md:32 px-4 py-2 bg-[#5733BD] text-white rounded-lg hover:bg-[#45289a]'
              onClick={onClose}
            >
              Notify Me
            </button>
            <button
              type='button'
              className='w-full md:32 px-4 py-2 bg-gray-300 rounded-lg hover:bg-gray-400'
              onClick={onClose}
            >
              Close
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default NotifyModal;
