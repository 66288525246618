import React, { useState } from "react";
import classes from "./ErrorBoundary.module.scss";

const ErrorBoundary = (props) => {
    const [hasError, setHasError] = useState(false);
  
    const handleSetHasError = (error) => {
      setHasError(true);
    };
  
    if (hasError) {
      // You can render any custom fallback UI
      return (
        <div className={classes.ErrorBoundaryWrapper}>
          <div className={classes.IconWrapper}>
            <div>Warning</div>
          </div>
          <div className={classes.ContentWrapper}>
            <h2>Oops, Something went wrong!</h2>
            <h4>Please try refreshing the page or try again after sometime.</h4>
          </div>
        </div>
      );
    }
  
    return (
      <>
        {React.cloneElement(props.children, {
          onError: handleSetHasError
        })}
      </>
    );
  };
  
  export default ErrorBoundary;