// import { createRef, useState } from 'react';
// import { Link } from 'react-router-dom';
import LaunchSoonModal from 'pages/Modals/LaunchSoonModal/LaunchSoonModal';
import { useState } from 'react';
import RestaurantImage from '../../images/restaurant.png';

import { trackEvent } from 'utils/trackevent';
import './PartnerWithUs.scss';

function PartnerWithUs() {
  const [isLaunchModalOpen, setIsLaunchModalOpen] = useState(false);

  const handleOpenLaunchModal = () => {
    trackEvent('button', 'click', 'signup_add_restaurant_clicked');
    setIsLaunchModalOpen(true);
  };

  const handleCloseLaunchModal = () => {
    trackEvent('button', 'click', 'signup_add_restaurant_closed');
    setIsLaunchModalOpen(false);
  };

  const openRestaurantSignUp = () => {
    const url = 'https://restaurant.kraverich.com/signup';
    window.open(url, '_blank');
  };

  const openRestaurantLogin = () => {
    const url = 'https://restaurant.kraverich.com/login';
    window.open(url, '_blank');
  };

  return (
    <section className='text-[#475467] bg-[#F9FAFB] px-5 md:px-0'>
      <h1 className='md:text-5xl text-3xl text-black title font-semibold pt-10 text-center'>
        Lets collaborate.
      </h1>
      <div className='border-[1px] rounded-xl bg-white p-5 md:w-[400px] w-full mx-auto my-10'>
        <div className='rounded-md border-[1px] w-10 h-10 flex items-center justify-center'>
          <img src={RestaurantImage} alt='restaurant' />
        </div>
        <h2 className='font-bold text-lg text-black my-3 '>
          Keep these documents ready.
        </h2>
        <ul className='list-decimal ml-6'>
          <li>FSSAI license</li>
          <li>GSTIN of restaurant</li>
          <li>PAN card of owner</li>
          <li>Menu</li>
        </ul>
        <button
          className='bg-purple text-white rounded-lg p-2 mt-10 w-full'
          onClick={openRestaurantSignUp}
        >
          Sign Up & add Restaurant
        </button>
        <button
          className='bg-white border-[1px] rounded-lg p-2 mt-2 w-full'
          onClick={openRestaurantLogin}
        >
          Already registered? Login
        </button>
        {isLaunchModalOpen && (
          <LaunchSoonModal onClose={handleCloseLaunchModal} />
        )}
      </div>

      <div className='flex md:flex-row flex-col items-center justify-center gap-5 py-10'>
        <div className='p-8 rounded-xl h-[280px] border-[1px] bg-white md:w-[20%] w-full  flex flex-col justify-around '>
          <h1 className='text-3xl text-black title md:text-left text-center font-semibold'>
            Elevate Your Brand.
          </h1>
          <p className='text-md mt-4 md:text-left text-center'>
            Partner with us to get featured among the best. Our insights &
            strategic approach will help you stand out from the competition.
          </p>
        </div>
        <div className='p-8 rounded-xl h-[280px] border-[1px] bg-white md:w-[20%] w-full flex flex-col justify-around'>
          <h1 className='text-3xl md:text-left text-center title text-black font-semibold'>
            Own Your Niche.{' '}
          </h1>
          <p className='text-md mt-4 md:text-left text-center'>
            We will help you own you niche with a custom experience tailored for
            your ideal customers. Don't get lost in the crowd.
          </p>
        </div>
        <div className='p-8 rounded-xl h-[280px] border-[1px] bg-white md:w-[20%] w-full flex flex-col justify-around'>
          <h1 className=' w-full text-3xl title md:text-left text-center text-black font-semibold'>
            See <br />
            Real ROI.{' '}
          </h1>
          <p className='text-md mt-4 md:text-left textF-center'>
            Low Commissions. Our collaborative partnership aims for mutual
            success encouraging long-term sustainable growth.
          </p>
        </div>
      </div>
      {/* <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
      >
        <div className='flex items-center justify-between'>
          <h2 className='text-2xl font-semibold'>
            Restaurant Details<sup>*</sup>
          </h2>
          <FaTimes size={20} className='cursor-pointer' onClick={closeModal} />
        </div>
        <div className='p-4'>
          <div className='flex flex-col gap-2'>
            <label htmlFor='restaurant-name'>
              Restaurant Name<sup>*</sup>
            </label>
            <input
              type='text'
              value={restaurantName}
              onChange={e => setRestaurantName(e.target.value)}
              name='restaurant-name'
              className='border-[1px] p-2 rounded-lg'
            />
          </div>
          <div className='flex flex-col gap-2 mt-5'>
            <label htmlFor='email'>
              Restaurant Address<sup>*</sup>
            </label>
            <input
              type='email'
              value={restaurantAddress}
              onChange={e => setRestaurantAddress(e.target.value)}
              name='email'
              className='border-[1px] p-2 rounded-lg'
            />
          </div>
          <div className='flex flex-col gap-2 mt-5'>
            <label htmlFor='phone'>
              Restaurant POC Name<sup>*</sup>
            </label>
            <input
              type='tel'
              id='phone'
              name='phone'
              value={restaurantPocName}
              onChange={e => setRestaurantPocName(e.target.value)}
              className='border-[1px] p-2 rounded-lg'
            />
          </div>
          <div className='flex justify-between items-center'>
            <div className='flex flex-col gap-2 mt-5'>
              <label>
                Restaurant POC mobile<sup>*</sup>
              </label>
              <input
                type='text'
                id='city'
                name='city'
                value={restaurantPocMobile}
                onChange={e => setRestaurantPocMobile(e.target.value)}
                className='border-[1px] p-2 rounded-lg'
              />
            </div>
            <div className='flex flex-col gap-2 mt-5'>
              <label>
                {' '}
                Restaurant POC email<sup>*</sup>
              </label>
              <input
                type='text'
                id='city'
                name='city'
                value={restaurantPocEmail}
                onChange={e => setRestaurantPocEmail(e.target.value)}
                className='border-[1px] p-2 rounded-lg'
              />
            </div>
          </div>
          <div className='flex justify-between items-center'>
            <div className='flex flex-col gap-2 mt-5'>
              <label>
                FSSAI license copy<sup>*</sup>
              </label>
              <div className='flex w-full items-center  border-[1px] rounded-lg cursor-default'>
                <span
                  onClick={() => {
                    fssaiLicenseRef.current.click();
                  }}
                  className='border-r-2 px-3 py-2  '
                >
                  {fssaiLicense ? fssaiLicense.name : 'Upload Here'}
                </span>
                <span className='font-semibold  py-2 px-4'>
                  {fssaiLicense ? (
                    <FaRegTrashAlt onClick={() => setFssaiLicense(undefined)} />
                  ) : (
                    'Browse'
                  )}
                </span>
                <input
                  type='file'
                  ref={fssaiLicenseRef}
                  onChange={e => {
                    setFssaiLicense(e.target.files[0]);
                  }}
                  className='hidden'
                />
              </div>
            </div>
            <div className='flex flex-col gap-2 mt-5'>
              <label>
                GSTIN of restaurant<sup>*</sup>
              </label>
              <div className='flex w-full items-center  border-[1px] rounded-lg cursor-default'>
                <span className='border-r-2 px-3 py-2  '>
                  {' '}
                  {gstin ? gstin.name : 'Upload Here'}
                </span>
                <span className='font-semibold  py-2 px-4'>
                  {gstin ? (
                    <FaRegTrashAlt onClick={() => setGstin(undefined)} />
                  ) : (
                    'Browse'
                  )}
                </span>
                <input
                  type='file'
                  ref={gstinRef}
                  onChange={e => {
                    setGstin(e.target.files[0]);
                  }}
                  className='hidden'
                />
              </div>
            </div>
          </div>
          <div className='flex justify-between items-center'>
            <div className='flex flex-col gap-2 mt-5'>
              <label>
                PAN card of owner<sup>*</sup>
              </label>
              <div className='flex w-full items-center  border-[1px] rounded-lg cursor-default'>
                {' '}
                <span className='border-r-2 px-3 py-2  '>
                  {' '}
                  {panCard ? panCard.name : 'Upload Here'}
                </span>
                <span className='font-semibold  py-2 px-4'>
                  {panCard ? (
                    <FaRegTrashAlt onClick={() => setPanCard(undefined)} />
                  ) : (
                    'Browse'
                  )}
                </span>
                <input
                  type='file'
                  ref={panCardRef}
                  onChange={e => {
                    setPanCard(e.target.files[0]);
                  }}
                  className='hidden'
                />
              </div>
            </div>
            <div className='flex flex-col gap-2 mt-5'>
              <label>
                Restaurant Menu<sup>*</sup>
              </label>
              <div className='flex w-full items-center  border-[1px] rounded-lg cursor-default'>
                {' '}
                <span className='border-r-2 px-3 py-2  '>
                  {' '}
                  {menu ? menu.name : 'Upload Here'}
                </span>
                <span className='font-semibold  py-2 px-4'>
                  {menu ? (
                    <FaRegTrashAlt onClick={() => setMenu(undefined)} />
                  ) : (
                    'Browse'
                  )}
                </span>
                <input
                  type='file'
                  ref={menuRef}
                  onChange={e => {
                    setMenu(e.target.files[0]);
                  }}
                  className='hidden'
                />
              </div>
            </div>
          </div>
          <button className='bg-purple text-white rounded-lg p-2 mt-5 w-full'>
            Sign Up & add Restaurant
          </button>
          <button className='bg-purple border-[1px] rounded-lg p-2 mt-5 w-full'>
            Already registered? Login
          </button>
        </div>
      </Modal> */}
    </section>
  );
}

export default PartnerWithUs;
