import { FaExclamationCircle } from 'react-icons/fa';

const NotFound = () => {
  return (
    <div className='flex items-center justify-center h-screen overflow-hidden'>
      <div className='text-center'>
        <FaExclamationCircle className='text-red-500 text-4xl' />
        <h1 className='text-4xl mt-4'>404 - Page Not Found</h1>
        <p className='text-lg mt-4'>
          Oops! The page you're looking for doesn't exist.
        </p>
      </div>
    </div>
  );
};

export default NotFound;
