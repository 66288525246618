import iima from '../../images/IIMA-ventures.svg';
import BulbVector from '../../images/about/bulb.svg';
import DiamondVector from '../../images/about/diamond.svg';
import GoalVector from '../../images/about/goal.svg';
import ClockIcon from '../../images/clock.png';
import LocationIcon from '../../images/marker-pin-02.png';
const positionsOpen = [
  {
    title: 'React Native Developer',
    location: 'Remote',
    type: 'Full-time',
  },
  {
    title: 'Senior Nutritionist',
    location: 'On-Site (IIMA, Ahmedabad)',
    type: 'Full-time',
  },
  {
    title: 'Operations Manager',
    location: 'On-Site (IIMA, Ahmedabad)',
    type: 'Full-time',
  },
  {
    title: 'Copywriter',
    location: 'Remote',
    type: 'Full-time',
  },
];

const About = () => {
  return (
    <div className=' text-[#475467] '>
      <div className='flex flex-col bg-[#F9F9F9] items-center text-center gap-2 p-8 md:p-20'>
        <h4 className='title text-3xl text-purple'>About</h4>
        <div className='md:px-14  md:text-[70px] text-4xl md:leading-[100px]  text-black font-bold title mt-10'>
          Redefining Life with Food{' '}
        </div>
        <div className='leading-5 text-center mt-5 text-lg'>
          <p className='font-bold text-xl  text-black my-3'>
            Ask yourself. What do I need to do, to be successful?
          </p>
          <p className='my-3 text-xl'>Set Goals? Take Action? Work hard?</p>
          <p className='font-semibold text-black my-5 md:text-3xl text-3xl'>
            Is it enough?
          </p>
        </div>
        <div className='flex flex-col items-center md:px-[200px] md:text-xl text-md bg-[#F9F9F9]'>
          <div className='border-[1px] rounded-xl shadow-md md:p-10 p-3 md:mx-[300px] w-full bg-white'>
            <p>
              Food is what assists you in that journey. Fuels your pursuit for
              greatness.
            </p>
            <p className='md:mt-5 mt-3'>
              We are revolutionising the way you approach life with food. We are
              driven by the belief that everyone deserves the opportunity to
              achieve their full potential, and We are dedicated to making
              personalised nutrition accessible and convenient without
              compromising on taste & desire.​
            </p>
          </div>
        </div>
      </div>

      <section className='md:px-[320px] px-4 mt-5'>
        <div className='flex md:flex-row flex-col justify-start md:gap-[100px] gap-5 items-center md:my-[90px] my-10'>
          <img
            src={GoalVector}
            alt='Mission'
            className='md:w-[180px] w-[100px]'
          />
          <div className='flex flex-col md:items-start items-center'>
            <h1 className='text-black font-semibold md:text-[40px] text-3xl'>
              Our Mission
            </h1>
            <p className=' mt-4  md:text-start text-center md:text-xl text-md md:px-0 px-4'>
              Empowering the driven to unlock their full potential through
              nutrition.
            </p>
          </div>
        </div>

        <div className='flex md:flex-row flex-col justify-start md:gap-[100px] gap-5 items-center md:my-[90px] my-10'>
          <img
            src={BulbVector}
            alt='Vision'
            className='md:w-[180px] w-[100px]'
          />
          <div className='flex flex-col md:items-start items-center'>
            <h1 className='text-black font-semibold md:text-[40px] text-3xl'>
              Our Vision
            </h1>
            <p className=' mt-4  md:text-start text-center md:text-xl text-md md:px-0 px-4'>
              A world where everyone has access to the right food, that fits
              every aspect of the being.
            </p>
          </div>
        </div>

        <div className='flex md:flex-row flex-col md:gap-[100px] gap-10 justify-start items-center md:my-[90px] my-10'>
          <img
            src={DiamondVector}
            alt='Values'
            className='md:w-[180px] w-[100px]'
          />
          <div className='flex flex-col md:items-start items-center'>
            <h1 className='text-black font-semibold md:text-[40px] text-3xl'>
              Our Values
            </h1>
            <p className='mt-4  md:text-start text-center md:text-xl text-md md:px-0 px-4'>
              Excellence, Empowerment, Transparency & Reliability​.
            </p>
          </div>
        </div>
      </section>

      {/* <section className='flex flex-col items-center text-center gap-2 p-7 md:p-8 bg-[#F9F9F9]'>
        <div className='md:text-4xl text-xl font-bold pb-10 text-purple'>
          Team
        </div>
        <div className='border-[1px] md:w-[200px] w-[150px] h-[200px] flex flex-col items-center rounded-xl bg-white p-4'>
          <div className='w-20 h-20 rounded-full border-[1px] overflow-hidden'>
            <img
              src={HemanthImage}
              alt='Hemanth'
              className=' object-cover w-full h-full'
            />
          </div>
          <h1 className='mt-4 text-lg font-bold title'>Hemanth Kumar</h1>
          <p className='text-sm'>Founder & CEO</p>
        </div>

        <div className='flex md:flex-col flex-row md:gap-5 gap-5 mt-2'>
          <div className='flex md:flex-row flex-col items-center gap-5 justify-between'>
            <div className='border-[1px] md:w-[200px] w-[150px] h-[200px] flex flex-col items-center rounded-xl bg-white p-4'>
              <div className='w-20 h-20 rounded-full border-[1px] overflow-hidden'>
                <img
                  src={BhavyaImage}
                  alt='Bhavya A'
                  className=' object-cover w-full h-full '
                />
              </div>
              <h1 className='mt-4 text-xl font-bold title'>Bhavya A</h1>
              <p className='text-sm'>Product Lead</p>
            </div>
            <div className='border-[1px] md:w-[200px] w-[150px] h-[200px] flex flex-col items-center rounded-xl bg-white p-4'>
              <div className='w-20 h-20 rounded-full border-[1px] overflow-hidden'>
                <img
                  src={VivekKNImage}
                  alt='Vivek K N'
                  className=' object-cover w-full h-full '
                />
              </div>
              <h1 className='mt-4 text-lg font-bold title'>Vivek K N</h1>
              <p className='text-sm'>Head of Nutrition Science</p>
            </div>
            <div className='border-[1px] md:w-[200px] w-[150px] h-[200px] flex flex-col items-center rounded-xl bg-white p-4'>
              <div className='w-20 h-20 rounded-full border-[1px] overflow-hidden'>
                <img
                  src={Mariya}
                  alt='Mariya Kagdi'
                  className=' object-cover w-full h-full '
                />
              </div>
              <h1 className='mt-4 text-lg font-bold title'>Mariya Kagdi</h1>
              <p className='text-sm'>Nutritionist</p>
            </div>{' '}
            <div className='border-[1px] md:w-[200px] w-[150px] h-[200px] flex flex-col items-center rounded-xl bg-white p-4'>
              <div className='w-20 h-20 bg-[#f3f4f7] border-[1px] flex justify-center items-center rounded-full overflow-hidden'>
                <img
                  src={SrinivasImage}
                  alt='Srinivas '
                  className='w-full h-full object-cover'
                />
              </div>
              <h1 className='mt-4 text-lg font-bold title'>Srinivas P</h1>
              <p className='text-sm'>Operations Lead</p>
            </div>
          </div>

          <div className='flex items-center md:flex-row flex-col gap-5 justify-between'>
            <div className='border-[1px] md:w-[200px] w-[150px] h-[200px] flex flex-col items-center rounded-xl bg-white p-4'>
              <div className='w-20 h-20 rounded-full border-[1px] overflow-hidden'>
                <img
                  src={PradyumnaImage}
                  alt='Pradyumna KS'
                  className=' object-cover w-full h-full '
                />
              </div>
              <h1 className='mt-4 text-lg font-bold title'>Pradyumna KS</h1>
              <p className='text-sm'>Product Designer</p>
            </div>
            <div className='border-[1px] md:w-[200px] w-[150px] h-[200px] flex flex-col items-center rounded-xl bg-white p-4'>
              <div className='w-20 h-20 bg-[#f3f4f7] border-[1px] flex justify-center items-center rounded-full overflow-hidden'>
                <RiUser3Line size={40} />
              </div>
              <h1 className='mt-4 text-lg font-bold title'>Ganesh</h1>
              <p className='text-sm'>Engineering Lead</p>
            </div>
            <div className='border-[1px] md:w-[200px] w-[150px] h-[200px] flex flex-col items-center rounded-xl bg-white p-4'>
              <div className='w-20 h-20 rounded-full border-[1px] overflow-hidden'>
                <img
                  src={VivekImage}
                  alt='Vivek Nimbolkar'
                  className=' object-cover w-full h-full '
                />
              </div>
              <h1 className='mt-4 text-lg font-bold title'>Vivek </h1>
              <p className='text-sm'>Full-stack Developer</p>
            </div>
            <div className='border-[1px] md:w-[200px] w-[150px] h-[200px] flex flex-col items-center rounded-xl bg-white p-4'>
              <div className='w-20 h-20 rounded-full border-[1px] overflow-hidden'>
                <img
                  src={KaushalImage}
                  alt='Kausahl'
                  className=' object-cover w-full h-full '
                />
              </div>
              <h1 className='mt-4 text-lg font-bold title'>Kaushal </h1>
              <p className='text-sm'>Full-stack Developer</p>
            </div>
          </div>
        </div>
      </section> */}

      <section className='flex flex-col items-center text-center justify-center gap-6 md:mt-8'>
        <div className='md:w-[75%] w-full'>
          <div className='p-8 flex flex-col gap-4'>
            <div className='font-bold title md:text-4xl text-xl text-purple'>
              {' '}
              Join us
            </div>
            <div className='flex flex-col items-center gap-5'>
              {positionsOpen.map((position, i) => {
                return (
                  <div
                    key={i}
                    className='md:w-3/4 w-full flex flex-col md:gap-4 gap-2 items-start justify-start  p-6 bg-[#F9F9F9] rounded-2xl '
                  >
                    <h1 className='title  text-lg font-bold'>
                      {position.title}
                    </h1>
                    <div className='flex items-center md:gap-10 gap-4'>
                      <div className='flex items-center gap-2'>
                        <img
                          src={LocationIcon}
                          width={15}
                          height={15}
                          alt='Location'
                        />
                        <div className='font-medium md:text-base text-xs'>
                          {position.location}
                        </div>
                      </div>
                      <div className='flex items-center gap-2'>
                        <img
                          src={ClockIcon}
                          width={15}
                          height={15}
                          alt='Type'
                        />
                        <div className='font-medium md:text-base text-xs'>
                          {position.type}
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
              <div className='flex flex-col rounded-2xl bg-[#F9F9F9] md:w-3/4 w-full p-5 items-center justify-center'>
                <p>
                  We are <strong>always seeking</strong> enthusiastic
                  individuals who are excited about 0-1 journey. Even if you
                  don’t find open position for your profile, we encourage you to
                  send us a mail with your resume.
                </p>
                <div className='w-fit bg-white p-3 px-5 border-[1px] rounded-lg mt-5 font-bold'>
                  hr.manager@kraverich.com
                </div>
              </div>
            </div>
          </div>
          <hr />
        </div>
      </section>

      <div className='flex items-center flex-col text-center p-16 gap-6'>
        <div className='font-bold text-2xl text-purple md:text-3xl'>
          Our Investors
        </div>
        <div>
          <img src={iima} alt='IIMA' className='h-10 md:h-14' />
        </div>
      </div>
    </div>
  );
};

export default About;
