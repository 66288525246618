import Footer from 'pages/Footer';
import background from '../../images/contactUs.svg';

const ContactUs = () => {
  return (
    <div
      className='mt-16'
      style={{
        backgroundImage: `url(${background})`,
        backgroundSize: 'contain, cover',
        // backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
      }}
    >
      <div className='max-w-2xl mx-auto p-4'>
        <form className='bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4 md:mt-20'>
          <div className='text-4xl font-bold text-center mb-4'>
            We'd love to hear from you
          </div>
          <div className='text-xl text-center mb-4'>
            Feel free to send your queries here
          </div>
          <div className='mb-4'>
            <label className={'block text-gray-700 text-sm font-bold mb-2'}>
              Full Name<sup style={{ color: 'red' }}>*</sup>
            </label>
            <input
              className='shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'
              id='fullName'
              type='text'
              placeholder='Full Name'
              required
            />
          </div>
          <div className='mb-4'>
            <label className='block text-gray-700 text-sm font-bold mb-2'>
              Contact Number
            </label>
            <input
              className='shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'
              id='fullName'
              type='number'
              placeholder='Contact Number'
            />
          </div>
          <div className='mb-4'>
            <label className='block text-gray-700 text-sm font-bold mb-2'>
              Email<sup style={{ color: 'red' }}>*</sup>
            </label>
            <input
              className='shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'
              id='email'
              type='email'
              placeholder='Email'
              required
            />
          </div>
          <div className='mb-4'>
            <label className='block text-gray-700 text-sm font-bold mb-2'>
              Reason<sup style={{ color: 'red' }}>*</sup>
            </label>
            <select
              id='reason'
              name='reason'
              className='shadow appearance-none  w-full border rounded-md py-2 px-4 focus:outline-none focus:border-blue-500'
              required
            >
              <option value=''>Select a reason</option>
              <option value='feedback'>Feedback</option>
              <option value='enquiry'>Enquiry</option>
              <option value='support'>Support</option>
            </select>
          </div>
          <div className='mb-6'>
            <label className='block text-gray-700 text-sm font-bold mb-2'>
              Message
            </label>
            <textarea
              className='shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'
              id='message'
              placeholder='Type your message here...'
              maxLength={300}
              rows={10}
            ></textarea>
          </div>
          <div className='flex items-center justify-between'>
            <button
              className='bg-green-600 hover:bg-green-900 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline'
              type='submit'
            >
              Submit
            </button>
          </div>
        </form>
      </div>

      <Footer />
    </div>
  );
};

export default ContactUs;
