import { useState } from 'react';
import AppStoreLogo from '../../images/app_store_logo.png';
import Mobile1 from '../../images/landing/content_1.webp';
import Mobile2 from '../../images/landing/content_2.webp';
import Mobile3 from '../../images/landing/content_3.webp';
import GooglePlayLogo from '../../images/landing/google-play.png';
import HeartImage from '../../images/landing/heart.png';
import SideImage1 from '../../images/landing/hero_image.webp';
import JumpImage from '../../images/landing/jump.png';

import NotifyModal from 'pages/Modals/NotifyModal/NotifyModal';
import { trackEvent } from 'utils/trackevent';
import PoweredImage from '../../images/iima-ventures.png';
import ValueImage from '../../images/landing/value.png';
import WatchImage from '../../images/landing/watch.png';
import './LandingPage.scss';

function LandingPage() {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOpenModal = () => {
    trackEvent('button', 'click', 'app_download_logo');
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    trackEvent('button', 'click', 'app_download_logo_closed');
    setIsModalOpen(false);
  };

  const openPlayStore = () => {
    const url =
      'https://play.google.com/store/apps/details?id=com.kraverich.app';
    window.open(url, '_blank');
  };
  const openAppleStore = () => {
    const url = 'https://apps.apple.com/in/app/kraverich/id6575390715';
    window.open(url, '_blank');
  };

  return (
    <>
      <div className=' text-[#475467] '>
        <section className='flex bg-[#F9F9F9] md:h-[80vh] md:flex-row flex-col items-center justify-between py-10 md:px-[160px] px-4'>
          <div className='md:w-[70%] flex flex-col md:items-start items-center md:text-left text-center justify-around  gap-9'>
            <div>
              <h1 className='md:text-[64px] text-black text-[50px] leading-[60px] font-[9000] title md:mt-5 mt-0'>
                Elevate Your{' '}
                <strong>
                  <span className='text-purple'>Lifestyle</span>
                </strong>{' '}
              </h1>
            </div>
            <p className='text-xl w-full'>
              Personalised meals that nourish you and empower your dreams.
              <br />
              Curated by nutritionists. Delivered only from best restaurants.
            </p>

            <div className='flex items-center gap-3'>
              <img
                src={AppStoreLogo}
                className='md:w-[155px] w-[130px] cursor-pointer'
                alt='App Store'
                onClick={openAppleStore}
              />
              <img
                src={GooglePlayLogo}
                className='md:w-[155px] w-[130px] cursor-pointer'
                alt='Google Play'
                onClick={openPlayStore}
              />
            </div>
            <p className='mt-[-25px]'>
              Kraverich is invite only. Get app to join invite list.
            </p>
            <div className='flex md:flex-col flex-row items-center md:gap-3 gap-3 shadow-md text-center p-4 rounded-xl'>
              <h3 className='text-sm md:text-md '>Proudly backed by</h3>
              <img src={PoweredImage} width={150} alt='Powered section' />
            </div>
          </div>
          <div className='md:flex flex-col justify-center items-center gap-4 md:mt-0 mt-20'>
            <img src={SideImage1} width={500} alt='side section screen' />
          </div>
        </section>

        <section className='text-[#475467] mt-16 md:text-left text-center md:px-[255px] '>
          <h1 className='font-bold md:text-4xl text-xl text-purple text-center title'>
            Why KraveRich?
          </h1>

          <div className='flex md:flex-row flex-col-reverse md:gap-20 gap-4 items-center justify-between md:my-20 my-10'>
            <img
              src={JumpImage}
              alt='Order Food'
              className='md:w-[400px] w-[350px] md:h-[400px]  h-[350px]'
            />
            <div className='md:flex hidden flex-col  md:items-start items-center'>
              <h2 className='title text-black md:px-0 font-semibold md:text-[40px] leading-[50px] w-full'>
                Perform better.
                <br /> Be the best version of yourself.
              </h2>
              <p className='md:mt-5 mt-3 md:text-[21px] leading-8 text-sm  '>
                Every bite can lead to a healthier, better you, where good taste
                meets good health.
              </p>
            </div>
            <div className='md:hidden flex flex-col  md:items-start items-center'>
              <h2 className='title text-black font-semibold text-2xl w-full'>
                Perform better.
              </h2>
              <h2 className='title text-black font-semibold text-2xl w-full'>
                Be the best version of yourself.
              </h2>
              <p className='mt-3 text-md w-full  md:px-0 px-10'>
                Every bite can lead to a healthier, happier you where good taste
                meets good health.
              </p>
            </div>
          </div>

          <div className='flex md:flex-row flex-col md:gap-20 gap-4 items-center justify-between  md:my-20 my-10'>
            <div>
              <h2 className='title text-black font-semibold md:text-[40px] leading-[50px] text-2xl md:mt-0 mt-10  w-full'>
                Get more time to do better things.{' '}
              </h2>
              <p className='md:mt-5 mt-3 md:text-xl text-md  md:px-0 px-10'>
                Stop scrolling to find what you might like.
                <br />
                Our intelligent recommendation engine will recommend based on
                your goal.
              </p>
            </div>
            <img
              src={WatchImage}
              alt='Order Food'
              className='md:w-[400px] w-[350px] md:h-[400px]  h-[350px]'
            />
          </div>

          <div className='flex md:flex-row flex-col-reverse items-center md:gap-20 gap-4 justify-between md:my-20 my-10'>
            <img
              src={HeartImage}
              alt='Order section'
              className='md:w-[400px] w-[350px] md:h-[400px]  h-[350px]'
            />
            <div>
              <h2 className='title text-black font-semibold md:text-[40px] text-2xl md:mt-0 mt-10  w-full'>
                Wellness. Effortless.{' '}
              </h2>
              <p className='md:mt-5 mt-3 md:text-xl text-md md:px-0 px-10'>
                Prevent and control lifestyle disorders.
                <br />
                Track your nutritional intake and receive recommendations based
                on your lifestyle.
              </p>
            </div>
          </div>
          <div className='flex md:flex-row flex-col md:gap-20 gap-4   items-center justify-between  md:my-20 my-10'>
            <div>
              <h2 className='title text-black md:text-[40px] leading-[50px] text-2xl  w-full'>
                No Surprises. Transparent pricing.{' '}
              </h2>
              <p className='md:mt-5 md:text-xl text-md w-[95%] md:px-0 px-10'>
                Fed up with hidden costs and overpriced meals? We provide
                transparent pricing. Taste the value with every dish.
              </p>
            </div>
            <img
              src={ValueImage}
              alt='Order Food'
              className='md:w-[400px] w-[350px] md:h-[400px]  h-[350px]'
            />
          </div>
        </section>

        <section className='md:px-[140px] w-full bg-[#F9F9F9]'>
          <h1 className='text-purple text-4xl text-center font-semibold py-10'>
            How it works?
          </h1>
          <div className='flex md:flex-row flex-col justify-evenly items-center md:gap-4 gap-8 md:px-0 px-10'>
            <div className='md:pb-14 pb-0'>
              <div className='flex items-center md:gap-8 gap-5 md:pl-6 pl-5 md:px-0 px-10 text-black justify-start '>
                <h4 className='md:text-5xl text-[32px] font-bold'>1</h4>
                <h4 className='md:text-[20px] text-sm md:text-md leading-[30px]  w-full md:w-[50%]'>
                  Enter your lifestyle details
                </h4>
              </div>
              <img src={Mobile1} alt='Mobile 1' className='mt-8' />
            </div>
            <div className='md:pb-14 pb-0'>
              <div className='flex items-center md:gap-8 gap-5 md:pl-6 pl-5  md:px-0 px-10 text-black justify-start '>
                <h4 className='md:text-5xl text-[32px] font-bold'>2</h4>
                <h4 className='md:text-[20px] text-sm md:text-md leading-[30px]  w-full md:w-[40%]'>
                  Track your nutrition{' '}
                </h4>
              </div>
              <img src={Mobile2} alt='Mobile 2' className='mt-8' />
            </div>
            <div className='md:pb-14 pb-0'>
              <div className='flex items-center md:gap-8 gap-5 md:pl-6 pl-5 md:px-0 text-black justify-start '>
                <h4 className='md:text-5xl text-[32px] font-bold'>3</h4>
                <h4 className='md:text-[20px] text-sm md:text-md leading-[30px] md:w-[50%]  w-full'>
                  Order from meal recommendations{' '}
                </h4>
              </div>
              <img src={Mobile3} alt='Mobile 3' className='mt-8' />
            </div>
          </div>
        </section>
        <section className='my-20'>
          <h1 className='text-black text-3xl text-center font-bold my-10'>
            Download KraveRich
          </h1>
          <div className='w-full flex flex-col items-center '>
            <div className='flex items-center gap-3'>
              <img
                src={AppStoreLogo}
                className='md:w-[155px] w-[130px] cursor-pointer'
                alt='App Store'
                onClick={openAppleStore}
              />
              <img
                src={GooglePlayLogo}
                className='md:w-[155px] w-[130px] cursor-pointer'
                alt='Google Play'
                onClick={openPlayStore}
              />
            </div>
            <p className='text-sm text-center my-4'>
              Kraverich is invite only. Get app to join invite list.
            </p>
            <span className='md:text-xl text-md mt-5 text-gray-400 px-5 md:w-[60%] w-full text-center font-semibold'>
              Food fuels your pursuit for greatness.
              <br /> Be your best. Bite by Bite.
            </span>
          </div>
        </section>
        {isModalOpen && <NotifyModal onClose={handleCloseModal} />}
      </div>
    </>
  );
}

export default LandingPage;
