// import Image from 'next/image';
import copyIcon from '../../../images/restaurant_partner/copy.svg';
import Image from '../../../images/restaurant_partner/launch_soon.png';
import mailIcon from '../../../images/restaurant_partner/mail.svg';
import phoneIcon from '../../../images/restaurant_partner/phone-call.svg';

function LaunchSoonModal({ onClose }) {
  const handleCopy = text => {
    navigator.clipboard.writeText(text);
  };

  return (
    <div
      className='fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50'
      onClick={onClose}
    >
      <div
        className='bg-white rounded-lg w-full max-w-xs mx-4 md:max-w-md'
        onClick={e => e.stopPropagation()}
        style={{ padding: '16px', gap: '16px', opacity: '1' }}
      >
        <div className='text-center mb-4'>
          <img
            src={Image}
            alt='Example'
            className='w-full max-w-sm mx-auto rounded-lg'
          />
        </div>
        <div className='bg-gray-100 p-4 rounded-lg w-full mx-auto md:w-96 border-[2px]'>
          <div className='flex justify-between items-center mb-2'>
            <div className='flex items-center'>
              <img src={phoneIcon} alt='Phone' className='mr-2' />
              <span>+91 96069 74913</span>
            </div>
            <img
              src={copyIcon}
              alt='Copy'
              className='cursor-pointer'
              // onClick={() => handleCopy('+91 96069 74913')}
            />
          </div>
          <div className='flex justify-between items-center'>
            <div className='flex items-center'>
              <img src={mailIcon} alt='Mail' className='mr-2' />
              <span>support@kraverich.com</span>
            </div>
            <img
              src={copyIcon}
              alt='Copy'
              className='cursor-pointer'
              // onClick={() => handleCopy('support@kraverich.com')}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default LaunchSoonModal;
